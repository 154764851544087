import { useFirestore } from '@/app/services/firebase/firebase.hooks';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';

export interface ExtendedUser extends User {
  isPaidSubscriber: boolean;
  searchCount: number;
}

export const queryAuth = () =>
  new Promise<ExtendedUser | null>((resolve, reject) => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(
      auth,
      async (user) => {
        if (user) {
          try {
            const db = getFirestore();
            const userDocRef = doc(db, 'users', user.uid);
            const userDoc = await getDoc(userDocRef);

            let isPaidSubscriber = false;
            let searchCount = 0;

            if (userDoc.exists()) {
              const userData = userDoc.data();
              isPaidSubscriber =
                userData?.subscription?.isPaidSubscriber || false;
              searchCount = userData?.searchCount || 0;
            } else {
              // Add the User Doc with default values if it doesn't exist
              await setDoc(userDocRef, {
                subscription: {
                  isPaidSubscriber: false, // Default value for new users
                },
                searchCount: 0, // Default value for new users
              });
            }

            // Return the user object with the isPaidSubscriber field
            resolve({ ...user, isPaidSubscriber, searchCount } as ExtendedUser);
          } catch (error) {
            console.error('Error fetching user subscription status:', error);
            reject(error);
          }
        } else {
          resolve(null);
        }
      },
      reject
    );

    return unsubscribe; // Return the unsubscribe function to allow stopping the listener if needed
  });
