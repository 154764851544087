export const colors = {
  brand: {
    // 100: '#797d80',
    // 200: '#63686b',
    // 300: '#4d5255',
    400: '#FFEC96',
    500: '#FFDA2A',
    600: '#CEB021',
    // 700: '#1a1f22',
    // 800: '#161b1e',
    // 900: '#13171a',
  },
  accent: {
    100: '#969a99',
    200: '#858988',
    300: '#737977',
    400: '#626866',
    500: '#505755',
    600: '#484e4d',
    700: '#404644',
    800: '#383d3b',
    900: '#303433',
  },
  yellow: {
    100: '#fae0b3',
    200: '#f9dba6',
    300: '#f8d699',
    400: '#f7d18d',
    500: '#f6cc80',
    600: '#ddb873',
    700: '#c5a366',
    800: '#ac8f5a',
    900: '#947a4d',
  },
  white: {
    100: '#fff',
    200: '#e0e9e6',
    300: '#dce6e2',
    400: '#d7e3df',
    500: '#d3e0db',
    600: '#becac5',
    700: '#a9b3af',
    800: '#949d99',
    900: '#7f8683',
  },
  red: {
    50: '#ff99b1',
    100: '#ff809d',
    200: '#ff6689',
    300: '#ff4d76',
    400: '#ff194f',
    500: '#ff003b',
    600: '#e60035',
    700: '#cc002f',
    800: '#b30029',
    900: '#990023',
  },
  pink: {
    50: '#ffc3d0',
    100: '#ffc0ce',
    200: '#f2b6c4',
    300: '#e6adb9',
    400: '#d9a3af',
    500: '#cc9aa5',
    600: '#bf909b',
    700: '#b38690',
    800: '#a67d86',
    900: '#99737c',
  },
  grayGreen: {
    500: '#93998e',
  },
};
