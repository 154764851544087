import React from 'react';
import { FlexPageWrapper } from './SignUp.styles';
import FormikHOC from './components/FormHOC';
import {
  Heading,
  Text,
  Button,
  Image,
  Flex,
  Link,
  Alert,
  AlertIcon,
  AlertDescription,
  useMediaQuery,
} from '@chakra-ui/react';
import { GoogleIcon } from '../../assets/logos';
import Form from './components/Form';
import { useSignUpFormHOC } from './services/SignUp.hooks';
import { useAppSelector } from '@/hooks/useRedux';

const SignUp: React.FC = () => {
  const { signUpWithGoogle } = useSignUpFormHOC();
  const { authError } = useAppSelector((state) => state.globalSlice);
  const [isDesktop] = useMediaQuery('(min-width: 1000px)');
  return (
    <FlexPageWrapper
      pb="50px"
    
      maxW={isDesktop ? '50vw' : 'unset'}
      mx={isDesktop ? 'auto' : 'unset'}
    >
      <Heading
        pt="35px"
        as="h1"
        fontSize="30px"
        marginBottom="4"
        textAlign="center"
        fontFamily="Britanica-Black"
        // letterSpacing="1px"
        fontWeight="500"
        //maxW="230px"
        // bgGradient="linear-gradient(157.4deg, #1085c7 7.96%, #fff 60%)"
        bg="white"
        backgroundClip="text"
        color="transparent"
        lineHeight="35px"
        mb="40px"
      >
        Create your free account
      </Heading>
      <Button
        aria-label="google"
        w="100%"
        maxW="300px"
        borderRadius="full"
        mb="30px"
        h="45px"
        onClick={() => signUpWithGoogle()}
      >
        {/* <Icon
            as={Google}
            // color="rgb(178 178 178)"
            mr="5px"
            boxSize="18px"
          /> */}
        <Image src={GoogleIcon} h="18px" mr="5px" />
        <Text>{'Google'}</Text>
      </Button>
      <Flex w="100%" alignItems="center" mb="30px" maxW="300px">
        <Flex h="2px" w="100%" bg="white"></Flex>
        <Text color="white" mx="20px">
          Or
        </Text>
        <Flex h="2px" w="100%" bg="white"></Flex>
      </Flex>
      <FormikHOC>
        <Form />
      </FormikHOC>
      {(() => {
        if (authError)
          return (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>{authError}</AlertDescription>
            </Alert>
          );

        return <></>;
      })()}

      <Text mt="20px" color="#A6A6A6" fontSize="14px" alignSelf="center">
        Already a member?{' '}
        <Link href="/signin" textDecoration="underline">
          Sign in
        </Link>
      </Text>

      <Text
        mt="30px"
        color="#A6A6A6"
        fontSize="14px"
        alignSelf="center"
        textAlign="center"
        zIndex={999}
      >
        By registering, you agree to the{' '}
        <Link href="/terms" textDecoration="underline">
          Terms of Use
        </Link>{' '}
        and{' '}
        <Link href="/privacy" textDecoration="underline">
          Privacy Policy
        </Link>
      </Text>
    </FlexPageWrapper>
  );
};

export default SignUp;
