import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Flex,
  Text,
  Icon,
  Heading,
} from '@chakra-ui/react';
import { Check } from '@styled-icons/boxicons-regular/Check';

interface UpgradeModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleSubscribe: () => void;
}

const UpgradeModal: React.FC<UpgradeModalProps> = ({
  isOpen,
  onClose,
  handleSubscribe,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton color="white" />
        <ModalBody>
          <Flex
            alignSelf="center"
            justifyContent="center"
            alignItems="center"
            w="100%"
            px="45px"
            mt="50px"
            maxW="400px"
          >
            <Flex
              w="100%"
              borderRadius="25px"
              bgGradient="linear(to-r, #f6d4a1, #ed8445)"
              backgroundImage="linear-gradient(117deg, #f6d4a1, #ed8445)"
              p=".125rem"
              alignSelf="center"
            >
              <Flex
                borderRadius="25px"
                flexDir="column"
                w="100%"
                bg="rgb(31 31 31)"
                pb="30px"
                boxShadow="
                0px 0px 0px 0px rgba(0, 0, 0, 0.1), 
                0px 7px 15px 0px rgba(0, 0, 0, 0.1), 
                0px 28px 28px 0px rgba(0, 0, 0, 0.09), 
                0px 63px 38px 0px rgba(0, 0, 0, 0.05), 
                0px 112px 45px 0px rgba(0, 0, 0, 0.01), 
                0px 175px 49px 0px transparent
                "
                backgroundImage="radial-gradient(58.99% 10.42% at 50% 100.46%, rgba(251, 188, 5, 0.07) 0%, transparent 100%), radial-gradient(135.76% 66.69% at 92.19% -3.15%, rgba(251, 5, 153, 0.1) 0%, transparent 100%), radial-gradient(127.39% 38.15% at 22.81% -2.29%, rgba(239, 145, 84, 0.4) 0%, transparent 100%)"
              >
                <Heading
                  pt="30px"
                  pl="30px"
                  as="h1"
                  fontSize="28px"
                  textAlign="left"
                  fontFamily="Britanica-Black"
                  fontWeight="500"
                  bg="white"
                  backgroundClip="text"
                  color="transparent"
                  lineHeight="35px"
                  mb="7px"
                >
                  Unlimited
                </Heading>
                <Text
                  pl="30px"
                  color="#AEAEAE"
                  textAlign="left"
                  fontSize="14px"
                  maxW="250px"
                  w="100%"
                  alignSelf="left"
                  mb="25px"
                >
                  For BJJ practitioners, competitors, and coaches
                </Text>
                <Flex alignItems="center" pl="30px">
                  <Text
                    pr="5px"
                    color="rgb(240 158 99)"
                    textAlign="left"
                    fontSize="16px"
                    maxW="250px"
                    alignSelf="left"
                    mt="14px"
                  >
                    US$
                  </Text>
                  <Text
                    pr="6px"
                    color="rgb(240 158 99)"
                    textAlign="left"
                    fontWeight="500"
                    fontSize="50px"
                    maxW="250px"
                    alignSelf="left"
                    mb="0px"
                  >
                    14.95
                  </Text>
                  <Text
                    color="rgb(240 158 99)"
                    textAlign="left"
                    fontSize="16px"
                    maxW="250px"
                    alignSelf="left"
                    mt="14px"
                    textDecoration="line-through"
                  >
                    /mo
                  </Text>
                </Flex>
                <Text
                  pl="30px"
                  color="#AEAEAE"
                  textAlign="left"
                  fontSize="14px"
                  maxW="250px"
                  alignSelf="left"
                  mb="25px"
                >
                  One-Time Payment – End Subscription Fatigue!
                </Text>
                <Button
                  borderRadius="25px"
                  bgGradient="linear(to-r, #f6d4a1, #ed8445)"
                  backgroundImage="linear-gradient(117deg, #f6d4a1, #ed8445)"
                  fontSize="14px"
                  mx="30px"
                  _hover={{
                    bgGradient: 'linear(to-r, #f0c687, #d96a28)',
                    transform: 'scale(1.05)',
                    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                  }}
                  transition="all 0.3s ease"
                  onClick={handleSubscribe}
                >
                  Select
                </Button>
                <Text
                  mt="30px"
                  pl="30px"
                  color="white"
                  textAlign="left"
                  fontWeight="bold"
                  fontSize="14px"
                  maxW="250px"
                  alignSelf="left"
                  mb="15px"
                >
                  Includes:
                </Text>
                <Flex alignItems="center" ml="22px">
                  <Icon as={Check} boxSize="6" color="#AEAEAE" mr="5px" />
                  <Text color="#AEAEAE" textAlign="center" fontSize="14px">
                    Unlimited sequences
                  </Text>
                </Flex>
                <Flex alignItems="center" ml="22px">
                  <Icon as={Check} boxSize="6" color="#AEAEAE" mr="5px" />
                  <Text color="#AEAEAE" textAlign="center" fontSize="14px">
                    Unlimited resources
                  </Text>
                </Flex>
                <Flex alignItems="center" ml="22px">
                  <Icon as={Check} boxSize="6" color="#AEAEAE" mr="5px" />
                  <Text color="#AEAEAE" textAlign="center" fontSize="14px">
                    Contribution priority
                  </Text>
                </Flex>
                <Flex alignItems="center" ml="22px">
                  <Icon as={Check} boxSize="6" color="#AEAEAE" mr="5px" />
                  <Text color="#AEAEAE" textAlign="center" fontSize="14px">
                    Email directly with dev
                  </Text>
                </Flex>
                <Flex alignItems="center" ml="16px" mr="16px" mt="15px">
                  <Text color="#AEAEAE" textAlign="center" fontSize="12px">
                    *Unlimited access is available for the duration of the
                    Tapdex project.
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UpgradeModal;
