import React from 'react';
import { FlexPageWrapper } from './SignIn.styles';
import FormikHOC from './components/FormHOC';
import {
  Heading,
  Text,
  Button,
  Image,
  Flex,
  Alert,
  AlertIcon,
  AlertDescription,
  useMediaQuery,
} from '@chakra-ui/react';
import { GoogleIcon } from '../../assets/logos';
import Form from './components/Form';
import { useSignInFormHOC } from './services/SignIn.hooks';
import { useAppSelector } from '@/hooks/useRedux';
import { NavLink } from 'react-router-dom';

const SignIn: React.FC = () => {
  const { signInWithGoogle } = useSignInFormHOC();
  const { authError } = useAppSelector((state) => state.globalSlice);
  const [isDesktop] = useMediaQuery('(min-width: 1000px)');
  return (
    <FlexPageWrapper
      pb="50px"
      maxW={isDesktop ? '50vw' : 'unset'}
      mx={isDesktop ? 'auto' : 'unset'}
    >
      <Heading
        pt="35px"
        as="h1"
        fontSize="30px"
        marginBottom="4"
        textAlign="center"
        fontFamily="Britanica-Black"
        // letterSpacing="1px"
        fontWeight="500"
        //maxW="230px"
        // bgGradient="linear-gradient(157.4deg, #1085c7 7.96%, #fff 60%)"
        bg="white"
        backgroundClip="text"
        color="transparent"
        lineHeight="35px"
        mb="40px"
      >
        Sign in
      </Heading>
      <Button
        aria-label="google"
        w="100%"
        borderRadius="full"
        mb="30px"
        h="45px"
        maxW="300px"
        onClick={() => signInWithGoogle()}
      >
        {/* <Icon
            as={Google}
            // color="rgb(178 178 178)"
            mr="5px"
            boxSize="18px"
          /> */}
        <Image src={GoogleIcon} h="18px" mr="5px" />
        <Text>{'Google'}</Text>
      </Button>
      <Flex w="100%" alignItems="center" mb="30px" maxW="300px">
        <Flex h="2px" w="100%" bg="white"></Flex>
        <Text color="white" mx="20px">
          Or
        </Text>
        <Flex h="2px" w="100%" bg="white"></Flex>
      </Flex>
      <FormikHOC>
        <Form />
      </FormikHOC>
      {(() => {
        if (authError)
          return (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>{authError}</AlertDescription>
            </Alert>
          );

        return <></>;
      })()}

      {/* <Text mt="20px" color="#A6A6A6" fontSize="14px" alignSelf="center">
        Don&apos;t have and account?{' '}
        
          <NavLink to="/signup" style={{fontWeight: 'bold', height: '40px', zIndex: 999}}>Sign up</NavLink>
     
      </Text> */}
      <NavLink
        to="/signup"
        style={{
          width: '100%',
          // fontWeight: 'bold',
          height: '40px',
          zIndex: 999,
          color: 'white',
          // flexDirection: 'row',
          // backgroundColor: 'red',
          textAlign: 'center',
          marginTop: '20px',
        }}
      >
        Don&apos;t have and account?{' '}
        <span style={{ fontWeight: 'bold' }}>Sign up</span>
      </NavLink>
      {/* <Button onClick={() => {
        console.log('clicked nav')
        navigate('/home')
        
        
        }}>
                  <Text>{<FormattedMessage id="navigation.home" />}</Text>
                </Button> */}
      {/* <Text
        mt="30px"
        color="#A6A6A6"
        fontSize="14px"
        alignSelf="center"
        textAlign="center"
      >
        By registering, you agree to the{' '}
        <NavLink to="/terms">
          <Text textDecoration="underline">Terms of Use</Text>
        </NavLink>{' '}
        and{' '}
        <NavLink to="/policy">
          <Text textDecoration="underline">Privacy Policy</Text>
        </NavLink>
      </Text> */}
    </FlexPageWrapper>
  );
};

export default SignIn;
