import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

type TLoadMoreButton = ButtonProps & {
  isLoading: boolean;
  isLastFetch: boolean;
  onLoadMore: () => void;
};

const LoadMoreButton: React.FC<TLoadMoreButton> = ({
  isLoading,
  isLastFetch,
  onLoadMore,
  ...rest
}) => {
  return (
    <Button
      isDisabled={isLoading || isLastFetch}
      onClick={onLoadMore}
      width="100%"
      mt="20px"
      mb="20px"
      // bg="blue.500"
      // color="white"
      bg="brand.500"
      _hover={{ bg: 'brand.400' }}
      {...rest}
    >
      {isLoading ? 'Loading...' : 'Load More'}
    </Button>
  );
};

export default LoadMoreButton;
