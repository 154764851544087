import React from 'react';
import { FlexWrapperForm } from '../../signin/SignIn.styles';
import Input from '@/common/components/Input/Input';
import { Text, Button, Flex } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { TResetPasswordForm } from '../ResetPassword.types';

const Form: React.FC = () => {
  const context = useFormikContext<TResetPasswordForm>();

  return (
    <FlexWrapperForm maxW="300px">
      <Input
        name="email"
        label="Email"
        labelProps={{ color: 'white' }}
        color="white"
      />

      <Flex justifyContent="center" mt="30px" mb="50px" alignItems="center">
        <Button
          aria-label="google"
          w="100%"
          h="45px"
          borderRadius="full"
          mb="20px"
          bg="#FFDA2A"
          onClick={() => context.submitForm()}
        >
          <Text>Reset Password</Text>
        </Button>
      </Flex>
    </FlexWrapperForm>
  );
};

export default Form;
