import React from 'react';
import { Outlet } from 'react-router-dom';
import { ContentContainer, Content } from './SingleLayout.styles';
import Footer from '../../common/ui/Footer/Footer';

export default function SingleLayout() {
  return (
    <ContentContainer>

      <Content>
        <Outlet />      
      </Content>

      <Footer />
    </ContentContainer>
  );
}
