import React, { ReactNode } from "react";
import Axios from "axios";
import { configure } from "axios-hooks";
import { getAuth } from "firebase/auth";
import LRU from "lru-cache";

export const axios = Axios.create({ baseURL: process.env.REACT_APP_API_URL });
/**
 * Clearing all browser default cache.
 * CloudFlair & our API Layer manages caching making this unnecessary.
 */
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.headers.common.Pragma = "no-cache";
axios.defaults.headers.common.Expires = "0";

axios.interceptors.request.use(async (config) => {
  const auth = getAuth();
  if (auth.currentUser === null) return config;
  const idToken = await auth.currentUser?.getIdToken(false);
  // console.log(idToken);
  const bearerToken = idToken ? `Bearer ${idToken}` : "";
  // eslint-disable-next-line
  config.headers!.Authorization = bearerToken;
  return config;
});
const cache = new LRU({ max: 10 });

configure({ axios, cache });

type TAxios = {
  children: ReactNode;
};

export default function AxiosProvider({ children }: TAxios) {
  return <>{children}</>;
}
