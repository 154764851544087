import React from 'react';
import { BoxProps, HStack, Text, Icon, Button } from '@chakra-ui/react';
import { ChevronLeft } from '@styled-icons/fa-solid/ChevronLeft';
import { ChevronRight } from '@styled-icons/fa-solid/ChevronRight';
import { FormattedMessage } from 'react-intl';
import { EPagination } from '@/api/dbModels.types';

type TPagination = BoxProps & {
  limit?: number;
  offset?: number;
  nextDisabled: boolean;
  previousDisabled: boolean;
  allDisabled?: boolean;
  onPageChange: (direction: EPagination) => void;
};

export const Pagination = ({
  onPageChange,
  offset = 0,
  limit = 20,
  nextDisabled,
  previousDisabled,
  allDisabled,
  ...rest
}: TPagination) => {
  /* don't show pagination when records are less than limit */
  if (nextDisabled && previousDisabled) return <></>;

  return (
    <HStack justifyContent="center" gap="10" my="2" mt="40px" {...rest}>
      {[...new Array(2)].map((_, i) => {
        const isPrevious = i === 0;

        return (
          <Button
            bg="brand.500"
            _hover={{
              bg: 'brand.400',
            }}
            w="120px"
            gap="5px"
            key={i}
            // isPrevious={isPrevious}
            isDisabled={
              (previousDisabled && isPrevious) ||
              (!isPrevious && nextDisabled) ||
              allDisabled
            }
            onClick={() =>
              onPageChange(isPrevious ? EPagination.Previous : EPagination.Next)
            }
          >
            {isPrevious && <Icon color="brand.700" as={ChevronLeft} />}
            <Text fontWeight="medium">
              {/* <FormattedMessage
                id={`generic.${isPrevious ? 'previous' : 'next'}`}
              /> */}
              {isPrevious ? 'Previous' : 'Next'}
            </Text>
            {!isPrevious && <Icon color="brand.700" as={ChevronRight} />}
          </Button>
        );
      })}
    </HStack>
  );
};
