import * as Yup from 'yup';

export const inputFormSchema = Yup.object().shape({
  youtubeId: Yup.string().required('YouTube ID is required'),
  sequence: Yup.string().required('Sequence or technique is required'),
  startingPosition: Yup.string().required('Starting position is required'),
  endingPosition: Yup.string().required('Ending position is required'),
  description: Yup.string().notRequired(),
  athlete: Yup.string().required('Athlete name is required'),
  timeStamp: Yup.string()
    .matches(
      /^(\d{1,2}:[0-5]\d:[0-5]\d|\d{1,2}:[0-5]\d)$/,
      'Invalid time format, try: (hh:mm:ss) or (mm:ss)'
    )
    .required('Time stamp is required'),
});

export const initialValues = {
  youtubeId: '',
  sequence: '',
  description: '',
  athlete: '',
  startingPosition: '',
  endingPosition: '',
  timeStamp: '',
};
