import { User } from 'firebase/auth';
import { useQuery, UseQueryOptions } from 'react-query';
import { keys } from '../api.keys';
import { queryAuth } from './authentication';

type TUseQueryAuthentication = {
  key?: string[];
  options?: UseQueryOptions<User | null>;
};

export const useQueryAuth = ({
  key = [],
  options = {},
}: TUseQueryAuthentication = {}) =>
  useQuery([keys.firebaseAuth, ...key], queryAuth, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(options as any),
  });