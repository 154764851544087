import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../common/ui/Header/Header';
import { ContentContainer, Content } from './PublicLayout.styles';
import Footer from '../../common/ui/Footer/Footer';

export default function PublicLayout() {
  return (
    <ContentContainer>
      <Header />

      <Content>
        <Outlet />      
      </Content>

      <Footer />
    </ContentContainer>
  );
}
