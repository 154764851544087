import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TLineData } from '../../../api/dbModels.types';

type TExploreState = {
  selectedLine: TLineData | undefined;
  lines: TLineData[];
  error: string;
  loading: boolean;
};

const initialState: TExploreState = {
  selectedLine: undefined,
  lines: [],
  error: '',
  loading: false,
};

const explore = createSlice({
  name: 'explore',
  initialState,
  reducers: {
    setSelectedLine: (
      state,
      action: PayloadAction<TExploreState['selectedLine']>
    ) => ({
      ...state,
      selectedLine: action.payload,
    }),
    setLines: (state, action: PayloadAction<TExploreState['lines']>) => ({
      ...state,
      lines: action.payload,
    }),
    setError: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
  },
});

export const { setSelectedLine, setLines, setLoading, setError } =
  explore.actions;

export default explore.reducer;
