import { ChakraProvider } from "@chakra-ui/react";
import { Global } from "@emotion/react";
import React, { ReactNode } from "react";
import slm from "../../../theme";
import { globalStyles } from "../../../theme/base/styles";

type TStyleSystem = {
  children: ReactNode;
};

export default function StyleSystem({ children }: TStyleSystem) {
  return (
    <ChakraProvider theme={slm}>
      <Global styles={globalStyles} />
      {children}
    </ChakraProvider>
  );
}
