import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TResource,
  TTechniqueOption,
  TTotals,
  TVideo,
} from '@/api/dbModels.types';

type TUser = {
  uid: string;
  email: string;
  isPaidSubscriber: boolean;
  searchCount: number;
};

type TGlobalSliceState = {
  selectedTechniqueLabels: TTechniqueOption[];
  techniqueLabels: TTechniqueOption[];
  videos: TVideo[];
  resources: TResource[];
  error: string;
  isLoading: boolean;
  isAuthLoading: boolean;
  authError: string;
  uuid: string;
  isSubscribed: boolean;
  subscriptionId: string;
  initialLoad: boolean;
  totals: TTotals;
  offset: number;
  isLastFetch: boolean;
  isYTLoading: boolean;
  prevSelectedTechniqueL: TTechniqueOption;
  user: TUser | null; // Add user field
};

const initialState: TGlobalSliceState = {
  selectedTechniqueLabels: [],
  techniqueLabels: [],
  videos: [],
  resources: [],
  error: '',
  isLoading: false,
  isAuthLoading: false,
  authError: '',
  uuid: '',
  isSubscribed: false,
  subscriptionId: '',
  initialLoad: false,
  totals: { rollsAndMatches: 0, sequences: 0, positions: 0, techniques: 0 },
  offset: 0,
  isLastFetch: false,
  isYTLoading: false,
  prevSelectedTechniqueL: { value: '', label: '' },
  user: null, // Initialize user as null
};
const globalSlice = createSlice({
  name: 'globalSlice',
  initialState,
  reducers: {
    setTechniqueLabels: (
      state,
      action: PayloadAction<TGlobalSliceState['techniqueLabels']>
    ) => ({
      ...state,
      techniqueLabels: action.payload,
    }),
    setSelectedTechniqueLabels: (
      state,
      action: PayloadAction<TGlobalSliceState['selectedTechniqueLabels']>
    ) => ({
      ...state,
      selectedTechniqueLabels: action.payload,
    }),
    setError: (state, action: PayloadAction<TGlobalSliceState['error']>) => ({
      ...state,
      error: action.payload,
    }),
    setVideos: (state, action: PayloadAction<TGlobalSliceState['videos']>) => ({
      ...state,
      videos: action.payload,
    }),
    setResources: (
      state,
      action: PayloadAction<TGlobalSliceState['resources']>
    ) => ({
      ...state,
      resources: action.payload,
    }),
    setAuthError: (
      state,
      action: PayloadAction<TGlobalSliceState['authError']>
    ) => ({
      ...state,
      authError: action.payload,
    }),
    setUuid: (state, action: PayloadAction<TGlobalSliceState['uuid']>) => ({
      ...state,
      uuid: action.payload,
    }),
    setIsSubscribed: (
      state,
      action: PayloadAction<TGlobalSliceState['isSubscribed']>
    ) => ({
      ...state,
      isSubscribed: action.payload,
    }),
    setSubscriptionId: (
      state,
      action: PayloadAction<TGlobalSliceState['subscriptionId']>
    ) => ({
      ...state,
      subscriptionId: action.payload,
    }),
    setIsAuthLoading: (
      state,
      action: PayloadAction<TGlobalSliceState['isAuthLoading']>
    ) => ({
      ...state,
      isAuthLoading: action.payload,
    }),
    setTotals: (state, action: PayloadAction<TGlobalSliceState['totals']>) => ({
      ...state,
      totals: action.payload,
    }),
    setOffset: (state, action: PayloadAction<TGlobalSliceState['offset']>) => ({
      ...state,
      offset: action.payload,
    }),
    setIsLastFetch: (
      state,
      action: PayloadAction<TGlobalSliceState['isLastFetch']>
    ) => ({
      ...state,
      isLastFetch: action.payload,
    }),
    setIsYTLoading: (
      state,
      action: PayloadAction<TGlobalSliceState['isYTLoading']>
    ) => ({
      ...state,
      isYTLoading: action.payload,
    }),
    setPrevSelectedTechniqueL: (
      state,
      action: PayloadAction<TGlobalSliceState['prevSelectedTechniqueL']>
    ) => ({
      ...state,
      prevSelectedTechniqueL: action.payload,
    }),
    setInitialLoad: (state, action: PayloadAction<boolean>) => ({
      ...state,
      initialLoad: action.payload,
    }),
    setIsLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoading: action.payload,
    }),
    setUser: (state, action: PayloadAction<TUser>) => ({
      ...state,
      user: action.payload,
    }),
    clearUser: (state) => ({
      ...state,
      user: null,
    }),
  },
});

export const {
  setSelectedTechniqueLabels,
  setTechniqueLabels,
  setVideos,
  setResources,
  setAuthError,
  setIsSubscribed,
  setUuid,
  setSubscriptionId,
  setIsAuthLoading,
  setInitialLoad,
  setTotals,
  setIsLastFetch,
  setOffset,
  setIsYTLoading,
  setPrevSelectedTechniqueL,
  setIsLoading,
  setError,
  setUser, // Export setUser action
  clearUser, // Export clearUser action
} = globalSlice.actions;

export default globalSlice.reducer;
