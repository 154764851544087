import React, { FC, ReactNode } from 'react';
import { SLMLogo } from '../../assets/logos';

import {
  Container,
  MainContent,
  ChildContentWrapper,
  Logo,
} from './ErrorLayout.styles';

export const ErrorLayout: FC<{ children: ReactNode }> = ({ children }) => (
  <Container>
    <MainContent>
      <Logo>
        <img src={SLMLogo} alt="SLM" />
      </Logo>
      <ChildContentWrapper>{children}</ChildContentWrapper>
    </MainContent>
  </Container>
);
