import React from 'react';
import { Box, Text, Heading, UnorderedList, ListItem } from '@chakra-ui/react';

const TermsAndConditions = () => {
  return (
    <Box p={10} color="white" w="100svw">
      <Heading as="h1" size="lg" w="100svw">
        Terms of Service
      </Heading>
      <Text mt={4}>Date of Last Revision: October 1, 2024</Text>

      <Heading as="h2" size="md" mt={8}>
        1. GENERAL INFORMATION
      </Heading>
      <UnorderedList mt={4}>
        <ListItem>
          TAPDEX PRODUCTS - These Terms & Conditions contain the terms and
          conditions on which we supply content, products, or services listed on
          www.tapdex.io.
        </ListItem>
        <ListItem>
          USERS IN THE EU AND CALIFORNIA – IMPORTANT NOTICE: By using our
          services, you acknowledge that you have read and understood our
          privacy practices. You have the right to access, rectify, and request
          deletion of your data, as per GDPR and CCPA regulations. For more
          details, please see our Privacy Policy.
        </ListItem>
        <ListItem>
          DISPUTE RESOLUTION – If you are a resident of the United States, you
          agree that any disputes will be resolved by binding arbitration, and
          you waive any right to participate in a class-action lawsuit or
          class-wide arbitration.
        </ListItem>
        <ListItem>
          CHANGES TO TERMS - AetherCode reserves the right to change or update
          these Terms at any time. If a revision is material, we will provide at
          least 30 days notice.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mt={8}>
        2. LICENSE
      </Heading>
      <UnorderedList mt={4} spacing="4">
        <ListItem>
          Subject to your compliance with these Terms and payment of the
          one-time fee of 14.95 USD, AetherCode grants you a limited,
          non-exclusive, non-transferable, revocable license to access and use
          the features provided by Tapdex.
        </ListItem>
        <ListItem>
          All right, title, and ownership in the Products remain with AetherCode
          or its licensors. The rights to use the Products are licensed to you
          and are not being sold to you.
        </ListItem>
        <ListItem>
          You agree not to copy, store, reproduce, transmit, modify, or
          reverse-engineer the Products.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mt={8}>
        3. SERVICE ACCESS AND TERMINATION
      </Heading>
      <UnorderedList mt={4}>
        <ListItem>
          AetherCode reserves the right to modify or discontinue any feature,
          content, or service provided through Tapdex at any time, for any or no
          reason, without liability to you or any third party.
        </ListItem>
        <ListItem>
          If the service is discontinued, users acknowledge that no refunds will
          be issued for any payments made. Access to the feature is granted for
          the duration of the service’s availability.
        </ListItem>
        <ListItem>
          Users may terminate their accounts at any time, but AetherCode is not
          obligated to delete or remove user content immediately.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mt={8}>
        4. PAYMENTS AND REFUNDS
      </Heading>
      <UnorderedList mt={4}>
        <ListItem>
          The one-time fee for accessing the feature is 14.95 USD. By making
          this payment, you agree that you will not be eligible for any refunds.
        </ListItem>
        <ListItem>
          AetherCode does not offer recurring subscriptions, and the one-time
          fee grants access for the duration of the service’s availability.
        </ListItem>
        <ListItem>
          Refunds for payments will not be issued, even if the service is
          modified, discontinued, or terminated.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mt={8}>
        5. ACCOUNTS AND SECURITY
      </Heading>
      <UnorderedList mt={4}>
        <ListItem>
          You are responsible for maintaining the confidentiality of your
          account and password. You agree to accept responsibility for all
          activities that occur under your account or password.
        </ListItem>
        <ListItem>
          You must notify us immediately upon becoming aware of any breach of
          security or unauthorized use of your account.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mt={8}>
        6. PRIVACY AND DATA PROTECTION
      </Heading>
      <UnorderedList mt={4}>
        <ListItem>
          If you are an EU or California resident, you have the right to access,
          rectify, or delete the personal data we hold about you, in accordance
          with GDPR and CCPA regulations. You also have the right to object to
          certain processing activities or request the portability of your data.
        </ListItem>
        <ListItem>
          To exercise any of these rights, please contact us at
          support@aethercode.com.au. We will respond to your request within the
          timeframe required by applicable law.
        </ListItem>
        <ListItem>
          AetherCode takes data privacy seriously. Please refer to our Privacy
          Policy for detailed information on how we collect, use, and protect
          your data.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mt={8}>
        7. LIMITATION OF LIABILITY
      </Heading>
      <UnorderedList mt={4}>
        <ListItem>
          To the fullest extent permitted by law, AetherCode shall not be liable
          for any damages or losses arising from your use of, or inability to
          use, the Products or from any modification, suspension, or
          discontinuation of the service.
        </ListItem>
        <ListItem>
          AetherCode makes no guarantee of availability or uptime, and reserves
          the right to take the service offline for maintenance or other reasons
          without liability.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mt={8}>
        8. GOVERNING LAW AND DISPUTE RESOLUTION
      </Heading>
      <UnorderedList mt={4}>
        <ListItem>
          These Terms are governed in accordance with the laws of Australia,
          without regard to its conflict of law principles.
        </ListItem>
        <ListItem>
          If you are located outside Australia, you agree that any disputes
          arising from or relating to these Terms will be resolved exclusively
          in Australian courts. However, nothing in these Terms affects your
          rights as a consumer to bring disputes in your local courts or to use
          your local laws where mandatory.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mt={8}>
        9. CONTACT INFORMATION
      </Heading>
      <UnorderedList mt={4}>
        <ListItem>
          For any questions about these Terms or your rights under GDPR, CCPA,
          or other privacy laws, please contact us at support@aethercode.com.au.
        </ListItem>
      </UnorderedList>
    </Box>
  );
};

export default TermsAndConditions;
