import React from 'react';
import { useField } from 'formik';
import { Textarea as ChakraTextarea, FormControl, FormLabel, FormErrorMessage, FormLabelProps, TextareaProps as ChakraTextareaProps } from '@chakra-ui/react';

interface TextAreaProps {
  label: string;
  name: string;
  placeholder?: string;
  labelProps?: FormLabelProps;
  textareaProps?: ChakraTextareaProps;
}

const TextArea: React.FC<TextAreaProps> = ({ label, labelProps, textareaProps, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <FormControl isInvalid={Boolean(meta.touched && meta.error)}>
      <FormLabel htmlFor={props.name} {...labelProps}>{label}</FormLabel>
      <ChakraTextarea {...field} {...props} {...textareaProps} id={props.name} />
      {meta.touched && meta.error ? (
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      ) : null}
    </FormControl>
  );
};

export default TextArea;