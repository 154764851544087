import { configureStore } from '@reduxjs/toolkit';
import navigationReducer from '../../common/ui/Navigation/services/navigation.slice';
import uploadReducer from '../../views/upload/services/Upload.slice';
import mapReducer from '../../common/components/Map/services/Map.slice'
import exploreReducer from '../../views/explore/services/Explore.slice'
import globalSliceReducer from '@/redux/Global.slice';

export const reducer = {
  globalSlice: globalSliceReducer,

  map: mapReducer,
  navigation: navigationReducer,
  upload: uploadReducer,
  explore: exploreReducer,
};

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
