import React from 'react';
import { useField } from 'formik';
import {
  Input as ChakraInput,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormLabelProps,
  InputProps,
  Tooltip,
  Icon,
  Flex,
} from '@chakra-ui/react';
import { InfoCircle } from '@styled-icons/boxicons-regular/InfoCircle';

interface IInputProps extends InputProps {
  label: string;
  name: string;
  type?: string;
  tooltip?: string;
  placeholder?: string;
  labelProps?: FormLabelProps;
}

const Input: React.FC<IInputProps> = ({
  label,
  labelProps,
  name,
  type,
  tooltip,
  ...props
}) => {
  const [field, meta] = useField({ name, type });

  return (
    <FormControl isInvalid={Boolean(meta.touched && meta.error)}>
      <Flex>
        <FormLabel htmlFor={name} {...labelProps}>
          {label}
        </FormLabel>

        {tooltip && <Tooltip label={tooltip} bg="black">
          <Icon as={InfoCircle} color="white" boxSize="20px" />
        </Tooltip>}
      </Flex>
      <ChakraInput {...field} name={name} type={type} {...props} id={name} />
      {meta.touched && meta.error ? (
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      ) : null}
    </FormControl>
  );
};

export default Input;
