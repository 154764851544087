export const en = {
  'navigation.signin': 'Sign In',
  'navigation.profile': 'Profile',
  'navigation.account': 'Account',
  'generic.save': 'Save',
  'generic.error.title': 'ERROR TITLE',
  'generic.error.header': 'ERROR HEADER',
  'generic.error.button': 'Try Again',
  'generic.error.message':
    'Oops! This page seems to have stumbled off the straight line. Please refresh the page or try again later. ',
  'generic.noresult.message':
    "Looks like you've stumbled upon uncharted territory! Adjust your search or create your own straight-line mission here.",
  'homepage.header': 'i',
  'homepage.input.email': 'Email',
  'homePage.howToGetStarted.getStarted.listItem1':
    'Open Google Earth Pro: Launch the application on your computer.',
  'homePage.howToGetStarted.getStarted.listItem2':
    "Draw a Line: Utilise Google Earth Pro's 'Line' tool to create your straight line. Use the Satellite View, Terrain Layer, and the Yellow Man.",
  'homePage.howToGetStarted.getStarted.listItem3':
    'Finally, tweak your line to circumnavigate populated areas and be mindful of steep slopes for a more practical straight-line mission.',
  'homePage.howToCompleteMission.listItem1':
    'Prepare Your Gear: Ensure you have appropriate clothing and equipment for rough terrain, as well as any necessary supplies for potential overnight stays.',
  'homePage.howToCompleteMission.listItem2':
    'Secure Communication: Carry an emergency satellite phone and/or GPS device for navigation and in case of emergencies.',
  'homePage.howToCompleteMission.listItem3':
    'Follow Your GPS: Use a reliable GPS device to guide you along your pre-planned straight-line route.',
  'homePage.howToCompleteMission.listItem4':
    'Be Adaptable: Prepare for possible detours or challenges such as bodies of water, cliffs, or other natural obstacles.',
  'homePage.howToCompleteMission.listItem5':
    'Stay Safe: Keep emergency contact details handy and ensure someone not participating knows your planned route and schedule.',
  'homePage.howToReviewAndUpload.listItem1':
    'Review in Google Earth: Open Google Earth Pro and double-check your line for any last-minute adjustments.',
  'homePage.howToReviewAndUpload.listItem2':
    "Name Your Lines: Ensure you have two lines – one named 'targetline' representing your goal, and another named 'path' showing the actual path you took.",
  'homePage.howToReviewAndUpload.listItem3':
    "Export Lines: Save these lines as a .kml file via Google Earth Pro's export feature.",
  'homePage.howToReviewAndUpload.listItem4':
    "Upload to Website: Use the 'Upload' feature on this website to share your straight line mission with the community.",
  'navigation.home': 'Home',
  'navigation.about': 'About',
  'navigation.community': 'Community',
  'navigation.upload': 'Upload',
  'searchbar.placeholder': 'Search by sequence',
  'upload.form.header': 'Upload a Straight Line Mission',
  'upload.form.header.body':
    'Easily upload your finished Straight Line with our upload tool.',
  'upload.form.body.header': 'Your line',
  'upload.form.button.upload': 'Upload KML File',
  'upload.form.button.imageUpload': 'Upload image',
  'upload.form.name': 'Name',
  'upload.form.ytLink': 'YouTube Link',
  'upload.form.description': 'Description',
  'upload.form.description.placeholder':
    'Angry farmer, steep cliffs, lots of brambles, am I recording? ...',
  'upload.form.saveLine': 'Save Line',
  'upload.form.completed.uploadAnother': 'Upload Another Line',
  'upload.form.completed.header': 'Great Success!',
  'upload.form.completed.header.body':
    'Your line will soon be visible on the map for everyone to admire.',
  'upload.form.error': 'Send this error message to the developer with a :(',
  'upload.form.error.tryagain': 'Try Again',
};
