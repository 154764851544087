import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: { hasUploaded: boolean, error: string, loading: boolean } = {
    hasUploaded: false,
    error: '',
    loading: false
};

const upload = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setHasUploaded: (
      state,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      hasUploaded: action.payload,
    }),
    setError: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      error: action.payload,
    }),
    setLoading: (
      state,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      loading: action.payload,
    }),
  },
});

export const { setHasUploaded, setLoading, setError } = upload.actions;

export default upload.reducer;
