import React from 'react';
import { FlexPageWrapper } from '../signin/SignIn.styles';

import {
  Heading,
  Icon,
  Text,
  Flex,
  TabIndicator,
  useMediaQuery,
  Tooltip,
  // Link,
} from '@chakra-ui/react';

import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import FormHOC from './components/FormHOC';
import Form from './components/Form';
import { useInputFormHOC } from './services/InputPage.hooks';
import { CheckCircle } from '@styled-icons/boxicons-solid/CheckCircle';
import { CloseCircle } from '@styled-icons/ionicons-sharp/CloseCircle';
const InputPage: React.FC = () => {
  const { pending, processed, loading } = useInputFormHOC();
  const [isDesktop] = useMediaQuery('(min-width: 1000px)');
  return (
    <FlexPageWrapper
      pb="50px"
      px="30px"
      alignItems="left"
      justifyContent="left"
      maxW={isDesktop ? '50vw' : 'unset'}
      mx={isDesktop ? 'auto' : 'unset'}
    >
      <Heading
        pt="35px"
        as="h1"
        fontSize="30px"
        marginBottom="4"
        // textAlign="center"
        fontFamily="Britanica-Black"
        // letterSpacing="1px"
        fontWeight="500"
        //maxW="230px"
        // bgGradient="linear-gradient(157.4deg, #1085c7 7.96%, #fff 60%)"
        bg="white"
        backgroundClip="text"
        color="transparent"
        lineHeight="35px"
        mb="20px"
      >
        Custom Input
      </Heading>
      <Text color="white" textAlign="left" mb="40px">
        Here you can add techniques and sequences to the data base
      </Text>
      <Tabs position="relative" variant="unstyled">
        <TabList>
          <Tab color="white">Input</Tab>
          <Tab color="white">Pending</Tab>
          <Tab color="white">Processed</Tab>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="#FFDA2A"
          borderRadius="1px"
        />
        <TabPanels>
          <TabPanel p="0">
            <FormHOC>
              <Form loading={loading}></Form>
            </FormHOC>
          </TabPanel>
          <TabPanel>
            <Flex
              flexDir="column"
              alignItems="center"
              justifyContent="center"
              mt="20px"
            >
              {pending.length ? (
                pending.map((p) => {
                  // console.log('🚀 ~ {pending.length&&pending.map ~ p:', p);
                  return (
                    <Flex
                      key={p.logId}
                      color="white"
                      w="100%"
                      justifyContent="space-between"
                      gap="15px"
                      py="15px"
                      borderBottom="1px"
                      borderColor="white"
                    >
                      <Text pr="5px">{p.dateTime}</Text>
                      <Text>{p.sequenceName}</Text>
                      <Text pl="5px">{p.logId}</Text>
                    </Flex>
                  );
                })
              ) : (
                <Text color="white">No pending input</Text>
              )}
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex
              flexDir="column"
              alignItems="center"
              justifyContent="center"
              mt="20px"
            >
              {processed.length ? (
                processed.map((p) => {
                  return (
                    <Flex
                      key={p.logId}
                      color="white"
                      w="100%"
                      justifyContent="space-between"
                      gap="15px"
                      py="15px"
                      borderBottom="1px"
                      borderColor="white"
                      // alignItems="center"
                    >
                      {p.approved ? (
                        <Icon as={CheckCircle} color="green" boxSize="20px" />
                      ) : (
                        <Tooltip label={p.rejectionReason} bg="black">
                          <Icon as={CloseCircle} color="red" boxSize="20px" />
                        </Tooltip>
                      )}
                      <Text>{p.sequenceName}</Text>
                      <Text pl="5px">{p.logId}</Text>
                    </Flex>
                  );
                })
              ) : (
                <Text color="white">No processed input</Text>
              )}
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </FlexPageWrapper>
  );
};

export default InputPage;
