import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { initialValues, signInSchema } from '../services/SignIn.config';
import { TFormikHOC, TSignInForm } from '../SignIn.types';
import { useSignInFormHOC } from '../services/SignIn.hooks';

export default function FormikHOC({ children }: TFormikHOC) {
  const { onSubmit } = useSignInFormHOC();

  return (
    <Formik<TSignInForm>
      initialValues={initialValues}
      validationSchema={signInSchema}
      onSubmit={async (
        values: TSignInForm,
        { setSubmitting, resetForm }: FormikHelpers<TSignInForm>
      ) => {
        // console.log("🚀 ~ FormikHOC ~ values:", values)

        setSubmitting(true);
        onSubmit(values);
        setSubmitting(false);
        resetForm({ values: initialValues });
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
}
