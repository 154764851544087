import React, { ReactElement } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { initialValues, inputFormSchema } from '../services/InputPage.config';
import { TFormikHOC, TSequenceLogForm } from '../InputPage.types';
import { useInputFormHOC } from '../services/InputPage.hooks';

export default function FormikHOC({ children }: TFormikHOC) {
  const { onSubmit, loading } = useInputFormHOC();

  return (
    <Formik<TSequenceLogForm>
      initialValues={initialValues}
      validationSchema={inputFormSchema}
      onSubmit={async (
        values: TSequenceLogForm,
        { setSubmitting, resetForm }: FormikHelpers<TSequenceLogForm>
      ) => {
        setSubmitting(true);
        onSubmit(values);
        setSubmitting(false);
        resetForm({ values: initialValues });
      }}
    >
      <Form>
        {React.Children.map(children, (child) =>
          React.isValidElement(child)
            ? React.cloneElement(child as ReactElement<any>, { loading })
            : child
        )}
      </Form>
    </Formik>
  );
}
