import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/* 
PrivateLayout is wrapped around every page, generating a new Navigation pannel on each page render. 
This slice keeps the expanded state across all pages.
*/

type TNavigationState = {
  expanded: boolean;
};

const initialState: TNavigationState = {
  expanded: true,
};

const navigation = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setExpanded: (state, action: PayloadAction<boolean>) => ({
      ...state,
      expanded: action.payload,
    }),
  },
});

export const { setExpanded } = navigation.actions;

export default navigation.reducer;