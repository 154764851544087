import React, { Fragment, ReactElement } from "react";
import { IntlProvider } from "react-intl";
import { en } from "../../../intl/en";

type TIntl = {
  children: ReactElement;
};

export default function Intl({ children }: TIntl) {
  return (
    <IntlProvider
      defaultLocale="en-AU"
      locale="en-AU"
      messages={en}
      textComponent={Fragment}
    >
      <>{children}</>
    </IntlProvider>
  );
}
