import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient } from 'react-query';
import store from './services/store';
import { AppContainer } from './App.styles';
import Routes from '../routes/Routes';
import Providers from './components/Providers';
import ErrorPage from '../views/errorpage/ErrorPage';
import { FirestoreProvider } from './services/firebase/firebase.hooks';



export const queryClient = new QueryClient();


const appErrorHandler = (error: Error, info: { componentStack: string }) => {
  // Do something with the error
  // E.g. log to an error logging client here

  console.error('Error handler', { error, info });
};

const App: FC = () => {

  // 👀 This is used by the error boundary. On error - the user clicks 'try again' and this triggers the browser router to reload
  // https://github.com/bvaughn/react-error-boundary#error-recovery
  const [errorKey, setErrorKey] = React.useState(false);

  return (
    <FirestoreProvider>
    <Providers client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <ErrorBoundary
            FallbackComponent={ErrorPage}
            onError={appErrorHandler}
            onReset={() => setErrorKey(false)}
            resetKeys={[errorKey]}
          >
            <Toaster />
            <AppContainer>
              <Routes />
            </AppContainer>
          </ErrorBoundary>
        </BrowserRouter>
      </Provider>
    </Providers>
    </FirestoreProvider>
  );
};

export default App;
