import { chakra, Text, Box } from "@chakra-ui/react";

export const ContainerTitle = chakra(Text, {
  baseStyle: () => ({
    fontSize: "2xl",
    fontWeight: "normal",
    color: "gray.500",
    mb: "5",
  }),
});

export const ContainerWrapper = chakra(Box, {
  baseStyle: () => ({
    bg: "white",
    overflow: "hidden",
    borderRadius: "0",
    boxShadow: "5px 5px 25px -10px rgba(0, 0, 0, 0.15)",
    marginBottom: "23px",
  }),
});
