/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PublicLayout from '../layouts/PublicLayout/PublicLayout';
import PrivateRoute from './components/PrivateRoute';
import RestrictedRoute from './components/RestrictedRoute';
import HomePage from '@/views/homepage/HomePage';
import PrivacyPolicy from '@/views/policies/privacy';
import TermsAndConditions from '@/views/policies/terms';
import SignIn from '@/views/signin/SignIn';
import SignUp from '@/views/signup/SignUp';
import ResetPassword from '@/views/resetpassword/ResetPassword';
import Account from '@/views/account/Account';
import InputPage from '@/views/inputpage/InputPage';
import Pricing from '@/views/pricing/Pricing';
import SingleLayout from '@/layouts/SingleLayout/SingleLayout';
import Faq from '@/views/faq/Faq';

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" replace />} />
      <Route element={<SingleLayout />}>
        <Route path="/pricing" element={<Pricing />} />
      </Route>
      <Route element={<PublicLayout />}>
        <Route path="/home" element={<HomePage />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsAndConditions />} />

        {/* <Route element={<MapWrapper />}>
          <Route path="/explore" element={<Explore />} />
          <Route path="/create" element={<Create />} />
          <Route path="/upload" element={<Upload />} />
        </Route> */}
      </Route>

      <Route element={<PublicLayout />}>
        <Route element={<RestrictedRoute />}>
          <Route path="/inputpage" element={<InputPage />} />
          {/* <Route path="/inputpage" element={<InputPage />} /> */}
          {/* <Route
            path="/login"
            element={
              <ErrorPage error={Error()} resetErrorBoundary={() => {}} />
            }
          /> */}
          {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
        </Route>
      </Route>

      <Route element={<PublicLayout />}>
        <Route element={<PrivateRoute />}>
          <Route path="/account" element={<Account />} />

          {/* <Route path="/bet-approvals">
            <Route element={<Card noBottomPad />}>
              <Route element={<BetApprovalsWrapper />}>
                <Route index element={<Navigate to="approvals" />} />
                <Route path="approvals" element={<BetsApprovalsList />} />
                <Route path="history" element={<BetsApprovalHistoryList />} />
              </Route>
            </Route>
          </Route> */}

          {/* <Route path="/placed-bets">
            <Route element={<Card noBottomPad />}>
              <Route element={<PlacedBetsWrapper />}>
                <Route index element={<Navigate to="upcoming" />} />
                <Route path="upcoming" element={<Upcoming />} />
                <Route path="resulted" element={<Resulted />} />
                <Route path="bets-dashboard" element={<BetsDashboard />} />
                <Route path="flagged" element={<Flagged />} />
              </Route>
            </Route>
          </Route> */}

          {/* <Route path="/bank">
            <Route element={<Card noBottomPad />}>
              <Route element={<BankWrapper />}>
                <Route
                  index
                  element={
                    <Navigate
                      to="withdrawals?withdrawalFilter=pending"
                      replace
                    />
                  }
                />
                <Route path="withdrawals" element={<Withdrawals />} />
                <Route path="deposits" element={<Deposits />} />
                <Route
                  path="analysis-dashboard"
                  element={<BankAnalysisDashboard />}
                />
              </Route>
            </Route>
          </Route> */}

          {/* <Route path="/trade-manager">
            <Route element={<Card noBottomPad />}>
              <Route element={<TradeManagerWrapper />}>
                <Route index element={<NextToJumpTab />} />

                <Route path="by-racing" element={<ByRacingTab />} />
                <Route path="by-sport" element={<BySportTab />} />

                <Route
                  path="highest-exposure"
                  element={<HighestExposureTab />}
                />
                <Route path="find-an-event" element={<FindAnEventTab />} />
                <Route
                  path="manage-my-custom-markets"
                  element={<ManageMyCustomMarketsTab />}
                />
                <Route
                  path="manage-global-margins"
                  element={<ManageGlobalMarginsTab />}
                />
                <Route path="multi-monitor" element={<MultiMonitor />} />
              </Route>
            </Route>
            <Route path="race-details" element={<RaceDetails />} />
            <Route path="match-details" element={<MatchDetails />} />
          </Route> */}

          {/* <Route path="/punter">
            <Route element={<PunterHeader />}>
              <Route element={<Card noBottomPad />}>
                <Route element={<PunterWrapper />}>
                  <Route index element={<Navigate to="/" replace />} />

                  <Route path=":punterId">
                    <Route index element={<Navigate to="bets" replace />} />
                    <Route path="bets" element={<Bets />} />
                    <Route path="payments" element={<Payments />} />
                    <Route path="deposit" element={<PunterDeposits />} />
                    <Route path="incentives" element={<Incentives />} />
                    <Route path="limits" element={<Limits />} />
                    <Route path="notes" element={<Notes />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="chat" element={<PunterDashboard />} />
                    <Route path="activity-log" element={<ActivityLog />} />
                    <Route path="transactions" element={<Transactions />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route> */}

          {/* <Route path="/settings">
            <Route element={<Card noBottomPad />}>
              <Route element={<SettingsWrapper />}>
                <Route index element={<Navigate to="limits" replace />} />

                <Route path="limits" element={<SettingsLimits />} />

                <Route
                  path="betfair-integration"
                  element={<BetfairIntegration />}
                />
                <Route path="system-setup" element={<SystemSetup />} />
                <Route path="admin-accounts" element={<AdminAccounts />} />
                <Route path="activity-log" element={<SettingsActivtyLogs />} />
                <Route path="your-profile" element={<YourProfile />} />
                <Route path="contact-us" element={<ContactDetails />} />

                <Route element={<SettingsOfferingsWrapper />}>
                  <Route path="offerings">
                    <Route
                      index
                      element={<Navigate to="offerings" replace />}
                    />
                    <Route path="offerings" element={<OfferingsTab />} />
                    <Route
                      path="featured-events"
                      element={<FeaturedEvents />}
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path="/promo-manager" element={<PromoManager />} />
          <Route path="/punters" element={<Punters />} /> */}

          <Route path="/*" element={<Navigate to="/" />} />
        </Route>
      </Route>
    </Routes>
  );
}
