import React from 'react';
import { FlexPageWrapper } from '../signin/SignIn.styles';
import FormikHOC from './components/FormHOC';
import {
  Heading,
  Text,
  Alert,
  AlertIcon,
  AlertDescription,
  useMediaQuery,
} from '@chakra-ui/react';
import Form from './components/Form';
import { useAppSelector } from '@/hooks/useRedux';
import { NavLink } from 'react-router-dom';

const ResetPassword: React.FC = () => {
  const { authError } = useAppSelector((state) => state.globalSlice);
  const [isDesktop] = useMediaQuery('(min-width: 1000px)');
  return (
    <FlexPageWrapper
      pb="50px"
      maxW={isDesktop ? '50vw' : 'unset'}
      mx={isDesktop ? 'auto' : 'unset'}
    >
      <Heading
        pt="35px"
        as="h1"
        fontSize="30px"
        marginBottom="4"
        textAlign="center"
        fontFamily="Britanica-Black"
        // letterSpacing="1px"
        fontWeight="500"
        //maxW="230px"
        // bgGradient="linear-gradient(157.4deg, #1085c7 7.96%, #fff 60%)"
        bg="white"
        backgroundClip="text"
        color="transparent"
        lineHeight="35px"
        mb="10px"
      >
        Reset your password
      </Heading>
      <Text color="white" textAlign="center" mb="40px">
        Enter your email address and we&apos;ll send you a link to reset your
        password
      </Text>

      <FormikHOC>
        <Form />
      </FormikHOC>
      {(() => {
        if (authError)
          return (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>{authError}</AlertDescription>
            </Alert>
          );

        return <></>;
      })()}
      <NavLink
        to="/signin"
        style={{
          width: '100%',
          // fontWeight: 'bold',
          height: '40px',
          zIndex: 999,
          color: 'white',
          // flexDirection: 'row',
          // backgroundColor: 'red',
          textAlign: 'center',
          marginTop: '20px',
        }}
      >
        Back to <span style={{ fontWeight: 'bold' }}>Sign in</span>
      </NavLink>
    </FlexPageWrapper>
  );
};

export default ResetPassword;
