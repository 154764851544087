/* eslint-disable react-hooks/exhaustive-deps */
import { TSequenceLogForm } from '../InputPage.types';
import { useQueryAuth } from '@/api/firebase/authentication.hooks';
import { useEffect, useState } from 'react';
import { useFirestore } from '@/app/services/firebase/firebase.hooks';
import { useToast } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { arrayUnion } from 'firebase/firestore';
import { ELogStatus, TLog, TSequence } from '@/api/dbModels.types';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

export const useInputFormHOC = () => {
  const { data } = useQueryAuth();
  const { firestore } = useFirestore();
  const toast = useToast();

  const [pending, setPending] = useState<TLog[]>([]);
  const [processed, setProcessed] = useState<TLog[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (data?.uid) {
      const fetchLogs = async () => {
        try {
          const userDocRef = firestore.collection('users').doc(data?.uid);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            const loggedSequences: TLog[] = userData?.loggedSequences || [];
            // console.log('Logged Sequences:', loggedSequences);
            const pendingLogs = loggedSequences.filter(
              (ls) => ls.status === ELogStatus.Pending
            );
            // console.log("🚀 ~ fetchPending ~ pendingLogs:", pendingLogs)
            const processedLogs = loggedSequences.filter(
              (ls) => ls.status === ELogStatus.Processed
            );
            // console.log("🚀 ~ fetchPending ~ processedLogs:", processedLogs)

            setPending(pendingLogs);
            setProcessed(processedLogs);
          } else {
            console.log('No such document!');
            return [];
          }
        } catch (error) {
          console.error('Error fetching pending documents:', error);
        }
      };

      // const fetchProcessed = async () => {
      //   try {
      //     const processedCollection = firestore.collection('processed');
      //     const querySnapshot = await processedCollection.where('user', '==', data.uid).get();
      //     const processedDocs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      //     console.log("🚀 ~ fetchProcessed ~ processedDocs:", processedDocs)

      //     setProcessed(processedDocs as any);
      //   } catch (error) {
      //     console.error('Error fetching processed documents:', error);
      //   }
      // };

      fetchLogs();
      // fetchProcessed();
    }
  }, [data?.uid]);

  // Function to convert "MM:SS" to seconds
  function convertToSeconds(timeString: string) {
    const [minutes, seconds] = timeString.split(':').map(Number);
    return minutes * 60 + seconds;
  }

  const onSubmit = async (form: TSequenceLogForm) => {
    if (!form) return;

    if (!data?.uid || !data?.email) return;

    setLoading(true);

    const id = uuidv4();

    const sequence: TSequence = {
      ...form,
      sequenceNumber: 0,
      timeStamp: convertToSeconds(form.timeStamp),
      _id: id,
      approved: false,
    };

    const log: TLog = {
      logId: id,
      sequenceName: form.sequence,
      sequence,
      dateTime: dayjs().toString(),
      userId: data.uid,
      userEmail: data.email,
      status: ELogStatus.Pending,
      approved: false,
      rejectionReason: '',
    };

    try {
      const userDocRef = firestore.collection('users').doc(data?.uid);
      await userDocRef
        .update({
          loggedSequences: arrayUnion(log),
        })
        .then(() => {
          console.log('Sequence saved successfully!');
          toast({
            title: 'Thanks!',
            description: 'Your sequence has been submitted.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          setLoading(false);
        });
      console.log('Log added successfully!');
    } catch (error) {
      console.error('Error adding log: ', error);
      setLoading(false);
    }

    // const sequencesCollection = firestore?.collection('sequences');

    // sequencesCollection
    //   ?.add(sequence)
    //   .then(() => {
    //     console.log('Sequence saved successfully!');
    //     toast({
    //       title: 'Thanks!',
    //       description: 'Your sequence has been submitted.',
    //       status: 'success',
    //       duration: 9000,
    //       isClosable: true,
    //     });
    //   })
    //   .catch((error) => {
    //     console.error('Error submitting sequence:', error);
    //   });
  };

  return {
    onSubmit,
    pending,
    processed,
    loading,
  };
};
