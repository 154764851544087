import React from 'react';
import { FlexWrapperForm } from '../SignIn.styles';
import Input from '@/common/components/Input/Input';
import { Text, Button, Flex, useMediaQuery } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { TSignInForm } from '../SignIn.types';
import { useNavigate } from 'react-router-dom';

const Form: React.FC = () => {
  const context = useFormikContext<TSignInForm>();
  const navigate = useNavigate();
  const [isDesktop] = useMediaQuery('(min-width: 1000px)');
  return (
    <FlexWrapperForm maxW="300px">
      <Input
        name="email"
        label="Email"
        labelProps={{ color: 'white' }}
        color="white"
      />
      <Input
        name="password"
        label="Password"
        type="password"
        labelProps={{ color: 'white' }}
        color="white"
      />
      <Flex justifyContent="center" mt="30px" mb="50px" alignItems="center">
        <Button
          bg="transparent"
          pl="2px"
          justifyContent="left"
          style={{
            textDecoration: 'underline',
            color: '#A6A6A6',
            minWidth: isDesktop ? 'unset' : '50vw',
            textAlign: 'left',
            // paddingBottom: '18px',
            // paddingLeft: '3px',
          }}
          onClick={() => navigate('/resetpassword')}
        >
          Forgot password?
        </Button>

        <Button
          aria-label="google"
          w="100%"
          h="45px"
          borderRadius="full"
          // mb="20px"
          // minW="200px"
          bg="#FFDA2A"
          onClick={() => {
            // console.log('TEST');
            // // console.log('🚀 ~ context:', context);
            context.submitForm();
          }}
        >
          <Text>Sign in</Text>
        </Button>
      </Flex>
    </FlexWrapperForm>
  );
};

export default Form;
