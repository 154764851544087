import { Skeleton } from '@chakra-ui/react';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useQueryAuth } from '../../api/firebase/authentication.hooks';
import { useAppSelector } from '@/hooks/useRedux';

export default function RestrictedRoute() {
  const { data, isLoading } = useQueryAuth();
  const { isSubscribed } = useAppSelector((state) => state.globalSlice);

  if (isLoading) return <Skeleton />;
  if (data && isSubscribed) {
    return <Outlet />;
  }

  return <Navigate to="/" replace />;
}
