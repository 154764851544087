/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FlexPageWrapper } from '../signin/SignIn.styles';
import {
  Heading,
  Icon,
  Text,
  Button,
  Flex,
  Spinner,
  useMediaQuery,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';
import { Check } from '@styled-icons/boxicons-regular/Check';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { Circle } from '@styled-icons/bootstrap/Circle';
import { useQueryAuth } from '@/api/firebase/authentication.hooks';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { NavLink } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { db } from '@/app/services/firebase/firebase.init';
import {
  setIsSubscribed,
  setSubscriptionId,
  setUuid,
} from '@/redux/Global.slice';

const stripePromise = loadStripe(
  'pk_live_51PePeCDxzmjDtvRTxE5AzIosaO8KlzpG8oKTwutKX0xCFdSkv8ah3NDBTSEIXaw1Om0LFPTl6jqhWOvCOc9WQubZ00LlVMefBo'
);

const Account: React.FC = () => {
  const { data } = useQueryAuth();
  // console.log('🚀 ~ data:', data);
  const dispatch = useAppDispatch();
  const { uuid, isSubscribed, subscriptionId, totals } = useAppSelector(
    (state) => state.globalSlice
  );
  const [loading, setLoading] = useState(false);

  const [isDesktop] = useMediaQuery('(min-width: 1000px)');

  useEffect(() => {
    // console.log('🚀 ~ ACCOUNT USEEFF fired', data);
    if (!data?.uid) return;
    const userRef = db.collection('users').doc(data?.uid);

    userRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const userData = doc.data();
          if (userData?.subscription && userData.subscription.id) {
            // console.log('🚀 ~ user data fetched and set');

            dispatch(setUuid(data?.uid));
            dispatch(setIsSubscribed(userData.subscription.isPaidSubscriber));
            dispatch(setSubscriptionId(userData.subscription.id));
          }
        } else {
          console.log('No such user!');
        }
      })
      .catch((error) => {
        console.error('Error getting user document:', error);
      });
  }, [data]);

  const handleCancelSubscription = async () => {
    setLoading(true);
    // setError(null);
    try {
      const response = await fetch(
        'https://us-central1-tapdex.cloudfunctions.net/cancelSubscription',
        {
          // Replace with your actual project ID
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            subscriptionId: subscriptionId,
            userId: uuid,
          }),
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }

      const { success } = await response.json();
      if (success) {
        console.log('Subscription cancelled successfully');
        // Update your UI or state as necessary
        const userRef = db.collection('users').doc(data?.uid);

        userRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              const userData = doc.data();
              if (userData?.subscription && userData.subscription.id) {
                // console.log(
                // '🚀 ~ user data fetched and set from cancelhandler'
                // );

                dispatch(setUuid(uuid));
                dispatch(
                  setIsSubscribed(userData.subscription.isPaidSubscriber)
                );
                dispatch(setSubscriptionId(userData.subscription.id));
              }
            } else {
              console.log('No such user!');
            }
          })
          .catch((error) => {
            console.error('Error getting user document:', error);
          });
      }
    } catch (error) {
      console.error('Error cancelling subscription', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubscribe = async () => {
    setLoading(true);
    const storedFbclid = localStorage.getItem('fbclid') || null; // Retrieve fbclid if stored

    try {
      const response = await fetch(
        'https://us-central1-tapdex.cloudfunctions.net/createCheckoutSession',
        {
          // Adjust URL if necessary
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: data?.email ?? '',
            userId: data?.uid ?? '',
            priceId: 'price_1Q6p57DxzmjDtvRT7pj9oc2e', // 'price_1PePmhDxzmjDtvRT6nFqzKyp', // Replace with your Stripe price ID
            storedFbclid,
          }),
        }
      );

      const { id } = await response.json();
      const stripe = await stripePromise;
      if (!stripe) {
        // console.log('🚀 ~ handleSubscribe ~ stripe is null:', stripe);
        return;
      }
      const { error } = await stripe.redirectToCheckout({ sessionId: id });

      if (error) {
        console.error('Error redirecting to Stripe Checkout:', error);
      }
    } catch (error) {
      console.error('Error creating checkout session', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FlexPageWrapper
      pb="50px"
      px="30px"
      alignItems="left"
      justifyContent="left"
      maxW={isDesktop ? '50vw' : 'unset'}
      mx={isDesktop ? 'auto' : 'unset'}
    >
      <Heading
        pt="35px"
        as="h1"
        fontSize="30px"
        marginBottom="4"
        // textAlign="center"
        fontFamily="Britanica-Black"
        // letterSpacing="1px"
        fontWeight="500"
        //maxW="230px"
        // bgGradient="linear-gradient(157.4deg, #1085c7 7.96%, #fff 60%)"
        bg="white"
        backgroundClip="text"
        color="transparent"
        lineHeight="35px"
        mb="20px"
      >
        Account
      </Heading>
      <Text color="#A6A6A6" textAlign="left">
        Full Name
      </Text>
      <Text color="#A6A6A6" textAlign="left" mb="40px">
        {data?.displayName}
      </Text>

      <Text color="#A6A6A6" textAlign="left">
        Email
      </Text>
      <Text color="#A6A6A6" textAlign="left" mb="40px">
        {data?.email}
      </Text>
      <NavLink
        to="/resetpassword"
        style={{
          width: '100%',
          // fontWeight: 'bold',
          height: '40px',
          zIndex: 999,
          color: 'white',
          // flexDirection: 'row',
          // backgroundColor: 'red',
          textDecoration: 'underline',
          textAlign: 'left',
          marginTop: '20px',
        }}
      >
        Click to change password
      </NavLink>

      <Text
        pt="35px"
        fontSize="20px"
        marginBottom="4"
        textAlign="left"
        fontFamily="Britanica-Black"
        // letterSpacing="1px"
        fontWeight="500"
        // bg="red"
        //maxW="230px"
        // bgGradient="linear-gradient(157.4deg, #1085c7 7.96%, #fff 60%)"
        w="100%"
        alignSelf="left"
        backgroundClip="text"
        color="white"
        lineHeight="35px"
        mb="10px"
      >
        Feature Access
      </Text>
      {/* {!isSubscribed && (
        <Alert
          status="info"
          mt="15px"
          mb="25px"
          pb="25px"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          maxW="700px"
          bg="#FFDA2A"
        >
          <AlertIcon mb="10px" color="rgb(43 43 43 / 1)" />

          <AlertDescription>
            While we are just getting started with sequences and resources being
            low, we are continuously adding more. By subscribing now, you not
            only ensure unlimited access to every new sequence added, but you
            also support the Tapdex vision and future of creating an affordable
            study tool. Join us as an early bird subscriber and help shape the
            future of grappling.
          </AlertDescription>
        </Alert>
      )} */}

      <Flex flexDir={'column'} alignItems="left">
        <Text color="white">{isSubscribed ? 'Unlimited' : 'Trial'}</Text>
        {!loading ? (
          <Button
            // pb="5px"
            // mb="5px"
            w="170px"
            variant="link"
            onClick={() => {
              handleSubscribe();
            }}
            display={isSubscribed ? 'none' : 'unset'}
            style={{ zIndex: 999 }}
          >
            <Text
              color="white"
              textAlign="center"
              textDecoration="underline"
              fontWeight="bold"
              mt="20px"
            >
              Get Unlimited Access
            </Text>
          </Button>
        ) : (
          <Spinner alignSelf="center" color="white" mt="5px" />
        )}
      </Flex>

      {isSubscribed && (
        <Text
          color="white"
          textAlign="left"
          fontSize="14px"
          // maxW="230px"
          alignSelf="left"
          mt="10px"
        >
          Contact dev: lev@tapdex.io
        </Text>
      )}
    </FlexPageWrapper>
  );
};

export default Account;
