/* eslint-disable no-unused-vars */
import firebase from 'firebase/compat/app';
import { EMedal } from '@/views/upload/Upload.types';
import { Coordinate, FeatureCollection } from '../views/upload/Upload.types';

// NEW
export enum EPagination {
  Previous = 'Previous', // Previous batch
  Initial = 'Initial', // First search - Feed is empty
  New = 'New', // New search after a sequence is already selected - Feed already showing sequences
  Next = 'Next', // Next batch
}

export type TTotals = {
  rollsAndMatches: number;
  sequences: number;
  positions: number;
  techniques: number;
};

export enum ELogStatus {
  Pending = 'Pending',
  Processed = 'Processed',
}

export type TLog = {
  logId: string;
  sequence: TSequence;
  sequenceName: string;
  dateTime: string;
  userId: string;
  userEmail: string;
  status: ELogStatus;
  approved: boolean;
  rejectionReason: string;
};

export type TSequence = {
  _id: string;
  youtubeId: string;
  sequence: string;
  description: string;
  athlete: string;
  startingPosition: string;
  endingPosition: string;
  timeStamp: number;
  approved: boolean;
  sequenceNumber: number;
};

export type TLogSequence = TLog & TSequence;

export type TDisplaySequence = {
  id: string;
  name: string;
  sequences: TSequence[];
};

export type TResource = {
  id: string;
  youtubeId: string;
  display: TDisplaySequence[];
};

// OLD

export type TTechniqueOption = {
  value: TTechniqueLabel;
  label: TTechniqueLabel;
};

export type TTechniqueLabel = string;

export type TTechnique = {
  label: TTechniqueLabel;
  id: string;
  videos: TTechniqueVideoReference[];
};

export type TTechniqueVideoReference = {
  technique: string; // TTechniqueLabel;
  id: string;
  timePoints: number[];
  athlete: string;
  type: string;
};

export type TTechniqueTimePoint = {
  technique: string;
  timePoints: number[];
};

export type TVideo = {
  id: string;
  techniques: TTechniqueTimePoint[];
};

export type TGeoDocumentData = {
  geohash: string;
  geopoint: firebase.firestore.GeoPoint;
};

export type TLineDataResponse = {
  id: string;
  g: TGeoDocumentData;
  geojsonData?: string; // the lines as GeoJSON string
  coordinates?: firebase.firestore.GeoPoint;
  burdellScore?: number;
  description?: string;
  fileName?: string;
  maxDeviation?: number;
  length?: number;
  elevationGain?: number;
  medal?: EMedal;
  ytUrl?: string;
  name?: string;
};

export type TLineData = {
  id: string;
  geojsonData: FeatureCollection; // the lines as GeoJSON FeatureCollection
  coordinates?: Coordinate;
  burdellScore?: number;
  description?: string;
  fileName?: string;
  maxDeviation?: number;
  length?: number;
  elevationGain?: number;
  medal?: EMedal;
  ytUrl?: string;
  name?: string;
  imageURL?: string;
};
