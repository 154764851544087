import { chakra, ChakraComponent, Flex, FlexProps, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

export const HeaderWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
    baseStyle: () => ({
      label: 'HeaderWrapper',
      h: '50px',
      pl: '2',
      pr: '2',
      transition: '.3s ease',
      alignItems: 'center',
      bg: 'rgb(43 43 43 / 1);', 
      zIndex: '1'
    }),
  });

  export const NavText = chakra(Text, {
    baseStyle: () => ({
      fontSize: '18px',
      fontWeight: 'medium',
      color: 'white',
    }),
  });


  export const StyledNavLink = chakra(NavLink, {
    baseStyle: () => ({
      h: '40px',
      display: 'flex',
      alignItems: 'center',
      my: '1.5',
      mx: '5',
  
      '&:hover': {
        bg: 'none',
      },
  
    }),
  });

  export const HighlightNavLink = chakra(NavLink, {
    baseStyle: () => ({
      h: '32px',
      display: 'flex',
      bg: '#32594A',
      // bg: 'linear-gradient(to right, #32594A, #437B63)',
      alignItems: 'center',
      my: '1.5',
      mx: '5',
      borderRadius: '20px',
      px: '3',
      transition: 'background 0.2s ease-in-out',
  
      '&:hover': {
         bg: '#233E33',
      },
  
    }),
  });