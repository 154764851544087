import { WithCSSVar } from '@chakra-ui/react';
import { GroupBase, StylesConfig } from 'react-select';
import { TOption } from './services/SearchBarTechniques.hooks';

export const searchBarStyles = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: WithCSSVar<Record<any, any>>
): StylesConfig<TOption, boolean, GroupBase<TOption>> => ({
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    backgroundColor: state.isSelected ? 'red' : 'red',
    fontSize: '18px', // Tokenise
    color:'red'
  }),
  
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: theme.sizes[4], 
    // backgroundColor: 'rgb(43 43 43 / 1)'
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: '99999',  // TOKEN
    fontSize: '18px', // Tokenise
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "red",
    color: "#292929",
    borderWidth:  '1px',
    '&:hover': state.isFocused
      ? {
          borderColor: '#C1CDC8',
        }
      : provided['&:hover'],

      boxShadow: state.isFocused
      ? 'inset 0 0 0 1px #93998e' // Apply a 2px thick box shadow when focused
      : 'none',
    // borderColor: state.isFocused
    //   ? '#93998e'
    //   : theme.colors.gray[200],

    minHeight: theme.sizes[8],
    minWidth: '350px',
    borderRadius: theme.radii.full,
    cursor: 'pointer',
    fontSize: '18px', // TOKEN
    // backgroundColor: 'rgb(43 43 43 / 1)'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? theme.colors.gray[600] : theme.colors.gray[500],
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '23px',
    fontFamily: 'Poppins', // Replace with your desired font family
  }),
  multiValue: (provided) => ({
    ...provided,
    fontSize: '23px',
    fontFamily: 'Poppins', // Replace with your desired font family
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '23px',
    fontFamily: 'Poppins', // Replace with your desired font family
    backgroundColor: 'rgb(43 43 43 / 1)'
  }),
});
