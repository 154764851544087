import React, { FC } from 'react';
import { components, DropdownIndicatorProps } from 'react-select';
import { Icon, IconButton } from '@chakra-ui/react';
import { Search } from '@styled-icons/feather/Search';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DropdownIndicator: FC<DropdownIndicatorProps<any, false>> = (
  props
) => (
  <components.DropdownIndicator {...props}>
    <IconButton
      aria-label="Search"
      h="8px"
      w="8px"
      mx="-2"
      // my="-4"
      borderLeftRadius="none"
      borderRightRadius="full"
      bg="transparent"
      justifyContent="center"
      _hover={{ bg: 'transparent' }}
    >
      <Icon as={Search} color="white" boxSize="20px" mr="0.5" />
    </IconButton>
  </components.DropdownIndicator>
);
