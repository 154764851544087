import { Box } from "@chakra-ui/react";
import React, { FC } from "react";
import { Motion } from "../Motion/Motion";
import { ContainerWrapper, ContainerTitle } from "./Card.styles";

interface CardProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
}

export const Card: FC<CardProps> = ({ children, title }) => (
  <Box>
    <Motion
      variants={{
        hidden: { opacity: 0, y: 20 },
        enter: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -20 },
      }}
    >
      <ContainerTitle>{title}</ContainerTitle>
    </Motion>

    <ContainerWrapper>{children}</ContainerWrapper>
  </Box>
);
