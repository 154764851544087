import React from 'react';
import { FlexWrapperForm } from '../InputPage.styles';
import Input from '@/common/components/Input/Input';
import { Text, Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { TSequenceLogForm } from '../InputPage.types';
import TextArea from '@/common/components/TextArea/TextArea';

const Form: React.FC<{ loading: boolean }> = ({
  loading,
}: {
  loading: boolean;
}) => {
  const context = useFormikContext<TSequenceLogForm>();
  return (
    <FlexWrapperForm>
      <Input
        name="youtubeId"
        label="YoutTube ID"
        tooltip="The 11 characters after v= in the YouTube url"
        labelProps={{ color: 'white' }}
        color="white"
        maxW="300px"
      />
      <Input
        name="athlete"
        label="Athlete"
        tooltip='e.g., "Nicky Ryan&apos;s brother"'
        labelProps={{ color: 'white' }}
        color="white"
        maxW="300px"
      />
      <Input
        name="startingPosition"
        label="Starting Position"
        labelProps={{ color: 'white' }}
        color="white"
        maxW="300px"
      />
      <Input
        name="endingPosition"
        label="Ending Position"
        labelProps={{ color: 'white' }}
        color="white"
        maxW="300px"
      />
      <Input
        name="sequence"
        label="Sequence / Technique"
        labelProps={{ color: 'white' }}
        color="white"
        maxW="300px"
      />
      <Input
        name="timeStamp"
        label="Time Stamp"
        tooltip="e.g., 01:22 (mm/ss) a couple seconds before the sequence begins"
        labelProps={{ color: 'white' }}
        color="white"
        w="30%"
        maxW="300px"
      />
      {/* <Input
        name="description"
        label="Description (optional)"
        labelProps={{ color: 'white' }}
        color="white"
      /> */}
      <TextArea
        name="description"
        label="Description / Details"
        labelProps={{ color: 'white' }}
        textareaProps={{ color: 'white', maxW: '300px' }}

        // color="white"
      />
      <Button
        isLoading={loading}
        aria-label="google"
        w="100%"
        h="45px"
        borderRadius="full"
        mb="20px"
        bg="#FFDA2A"
        mt="20px"
        maxW="200px"
        onClick={() => context.submitForm()}
      >
        <Text>Submit Form</Text>
      </Button>
    </FlexWrapperForm>
  );
};

export default Form;
