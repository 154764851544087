/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { useQueryAuth } from '@/api/firebase/authentication.hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  setIsSubscribed,
  setSubscriptionId,
  setTotals,
  setUuid,
  setIsAuthLoading,
} from '@/redux/Global.slice';
import { db } from '@/app/services/firebase/firebase.init';
import { loadStripe } from '@stripe/stripe-js';
import { useFirestore } from '@/app/services/firebase/firebase.hooks';
import { TTotals } from '@/api/dbModels.types';
import { ExtendedUser } from '@/api/firebase/authentication';

const stripePromise = loadStripe(
  // 'pk_test_51PePeCDxzmjDtvRT4g00SBv8WUpZqpru41G42MRJONvvZ7V3eFyREP4hG943BdzQej50xVdljFGNpYSHYIeM7F2j00EgOskmM0'
  'pk_live_51PePeCDxzmjDtvRTxE5AzIosaO8KlzpG8oKTwutKX0xCFdSkv8ah3NDBTSEIXaw1Om0LFPTl6jqhWOvCOc9WQubZ00LlVMefBo'
);

export const useSubscribe = () => {
  const { data } = useQueryAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { uuid, isSubscribed, subscriptionId } = useAppSelector(
    (state) => state.globalSlice
  );
  const [loading, setLoading] = useState(false);

  // MOVE TO OTHER HOOK
  const { firestore } = useFirestore();

  useEffect(() => {
    const fetchAggregates = async () => {
      try {
        // Reference to the aggregates document
        const aggregatesRef = firestore.collection('aggregates').doc('totals');
        const doc = await aggregatesRef.get();

        if (doc.exists) {
          const data = doc.data();
          if (!data) return;
          dispatch(setTotals(data as TTotals));
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching aggregates: ', error);
      }
    };

    fetchAggregates();
  }, []);
  // ------

  useEffect(() => {
    if (!data?.uid) return;
    // console.log('🚀 ~ useEffect ~ useSubscibe:');
    const userRef = db.collection('users').doc(data?.uid);

    userRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const userData = doc.data();
          if (userData?.subscription && userData.subscription.id) {
            // console.log('🚀 ~ user data fetched and set');

            dispatch(setUuid(data?.uid));
            dispatch(setIsSubscribed(userData.subscription.isPaidSubscriber));
            dispatch(setSubscriptionId(userData.subscription.id));
          }
        } else {
          console.log('No such user!');
        }
      })
      .catch((error) => {
        console.error('Error getting user document:', error);
      });
  }, [data]);

  const handleCancelSubscription = async () => {
    setLoading(true);
    // setError(null);
    try {
      const response = await fetch(
        'https://us-central1-tapdex.cloudfunctions.net/cancelSubscription',
        {
          // Replace with your actual project ID
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            subscriptionId: subscriptionId,
            userId: uuid,
          }),
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }

      const { success } = await response.json();
      if (success) {
        console.log('Subscription cancelled successfully');
        // Update your UI or state as necessary
        const userRef = db.collection('users').doc(data?.uid);

        userRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              const userData = doc.data();
              if (userData?.subscription && userData.subscription.id) {
                // console.log(
                // '🚀 ~ user data fetched and set from cancelhandler'
                // );

                dispatch(setUuid(uuid));
                dispatch(
                  setIsSubscribed(userData.subscription.isPaidSubscriber)
                );
                dispatch(setSubscriptionId(userData.subscription.id));
              }
            } else {
              console.log('No such user!');
            }
          })
          .catch((error) => {
            console.error('Error getting user document:', error);
          });
      }
    } catch (error) {
      console.error('Error cancelling subscription', error);
    } finally {
      setLoading(false);
    }
  };

  function getCookie(name: string): string | null {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop()?.split(';').shift() || null;
    }
    return null;
  }

  const handleSubscribe = async (user?: ExtendedUser) => {
    console.log('🚀 ~ handleSubscribe ~ user:', user);

    if (!user) {
      // console.log("🚀 ~ handleSubscribe ~ User is not logged in - data:", data)
      // User is not logged in, navigate to signup page with state indicating the intent to subscribe
      navigate('/signup', { state: { fromSubscribe: true } });
      return;
    }

    setLoading(true);

    const storedFbclid = localStorage.getItem('fbclid') || null; // Retrieve fbclid if stored

    // Retrieve fbp from the Facebook cookie
    const fbp: string | null = getCookie('_fbp');

    try {
      const response = await fetch(
        'https://us-central1-tapdex.cloudfunctions.net/createCheckoutSession',
        {
          // Adjust URL if necessary
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: user?.email ?? '',
            userId: user?.uid ?? '',
            priceId: 'price_1Q6p57DxzmjDtvRT7pj9oc2e', // 'price_1PePmhDxzmjDtvRT6nFqzKyp', // Replace with your Stripe price ID
            storedFbclid,
            fbp,
          }),
        }
      );

      const { id } = await response.json();
      const stripe = await stripePromise;

      dispatch(setIsAuthLoading(false));
      if (!stripe) {
        // console.log('🚀 ~ handleSubscribe ~ stripe is null:', stripe);
        return;
      }
      const { error } = await stripe.redirectToCheckout({ sessionId: id });

      if (error) {
        console.error('Error redirecting to Stripe Checkout:', error);
      }
    } catch (error) {
      console.error('Error creating checkout session', error);
    } finally {
      setLoading(false);
    }
  };

  return {
    handleSubscribe,
    handleCancelSubscription,
    isSubscribed,
    loading,
  };
};
