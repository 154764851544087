import React from 'react';
import {
  Box,
  Text,
  Heading,
  Link,
  UnorderedList,
  ListItem,
  List,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';

const PrivacyPolicy = () => {
  return (
    <Box p={10} w="100svw" color="white">
      <Heading mb={4}>Privacy Policy</Heading>
      <Text mb={2}>Effective Date: 1 August 2024</Text>

      <Text mb={4}>
        Welcome to the Privacy Policy of AetherCode Pty Ltd. (“AetherCode,”
        “Tapdex,” “we,” “us,” or “our”). Tapdex provides a digital platform
        dedicated to Brazilian Jiu-Jitsu (BJJ) practitioners. This Privacy
        Policy applies to our services and explains what Personal Data (defined
        below) we collect, how we use and share that data, and your choices
        concerning our data practices. By using our Service, you agree to the
        collection, use, and disclosure of your information as described in this
        Privacy Policy.
      </Text>

      <Heading size="md" mb={2}>
        1. INFORMATION WE COLLECT
      </Heading>
      <Text mb={2}>
        When you contact us or interact with our Service, we collect information
        that alone or in combination with other information could be used to
        identify you (“Personal Data”). This includes:
      </Text>

      <UnorderedList mb={4}>
        <ListItem>
          **Personal Data You Provide to Us**:
          <UnorderedList>
            <ListItem>
              Account Data: Includes your name, username, email address, and
              other relevant information needed to create an account.
            </ListItem>
            <ListItem>
              Payment Data: Collected by our third-party processor (e.g.,
              Stripe). We do not store your payment details directly.
            </ListItem>
            <ListItem>
              User-Generated Content: Such as profile pictures, posts, and other
              information you choose to share on the platform.
            </ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem>
          **Personal Data We Collect Automatically**:
          <UnorderedList>
            <ListItem>
              Log Data: IP address, browser type, and device information.
            </ListItem>
            <ListItem>
              Cookies Data: Information gathered via cookies and similar
              technologies. Refer to our Cookie Policy for more information.
            </ListItem>
          </UnorderedList>
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mt={5}>
        2. HOW WE USE YOUR DATA
      </Heading>
      <Text mt={3}>
        We use your Personal Data for various purposes including:
      </Text>
      <UnorderedList mt={3}>
        <ListItem>
          To create and manage your account (Contractual Necessity).
        </ListItem>
        <ListItem>To process payments (Contractual Necessity).</ListItem>
        <ListItem>To provide customer support (Legitimate Interests).</ListItem>
        <ListItem>
          To comply with legal obligations (Compliance with Law).
        </ListItem>
        <ListItem>To send marketing communications (Consent).</ListItem>
      </UnorderedList>

      <Heading as="h2" size="md" mt={5}>
        3. YOUR RIGHTS UNDER GDPR (EEA/UK USERS) AND CCPA (CALIFORNIA USERS)
      </Heading>
      <Text mt={3}>
        If you are located in the EEA, UK, or California, you have specific
        rights regarding your Personal Data, including:
      </Text>
      <UnorderedList mt={3}>
        <ListItem>
          **Access**: Request a copy of the Personal Data we hold about you.
        </ListItem>
        <ListItem>
          **Rectification**: Correct inaccurate or incomplete data.
        </ListItem>
        <ListItem>
          **Erasure**: Request deletion of your Personal Data.
        </ListItem>
        <ListItem>
          **Data Portability**: Receive your Personal Data in a structured
          format.
        </ListItem>
        <ListItem>
          **Restriction**: Request that we limit the processing of your data.
        </ListItem>
        <ListItem>
          **Object**: Object to the processing of your data for marketing
          purposes.
        </ListItem>
        <ListItem>
          **Do Not Sell My Personal Information** (CCPA only): You have the
          right to opt out of the sale of your Personal Data.
        </ListItem>
      </UnorderedList>
      <Text mt={3}>
        To exercise your rights, please contact us at support@aethercode.com.au.
        We will respond within the timeframe required by applicable law.
      </Text>

      <Heading as="h2" size="md" mt={5}>
        4. INTERNATIONAL DATA TRANSFERS
      </Heading>
      <Text mt={3}>
        We may transfer your data outside of your country of residence. We
        ensure that your Personal Data is adequately protected according to
        applicable laws, such as through the use of standard contractual clauses
        approved by the European Commission.
      </Text>

      <Heading as="h2" size="md" mt={5}>
        5. SECURITY
      </Heading>
      <Text mt={3}>
        We implement industry-standard measures to protect your Personal Data.
        However, please note that no internet transmission is 100% secure, and
        you share information at your own risk.
      </Text>

      <Heading as="h2" size="md" mt={5}>
        6. CHANGES TO THIS PRIVACY POLICY
      </Heading>
      <Text mt={3}>
        We may update this Privacy Policy to reflect changes in our practices.
        We will notify you of any material changes as required by law.
      </Text>

      <Heading as="h2" size="md" mt={5}>
        7. CONTACT INFORMATION
      </Heading>
      <Text mt={3}>
        If you have any questions or concerns, please contact us at
        support@aethercode.com.au.
      </Text>
    </Box>
  );
};

export default PrivacyPolicy;
