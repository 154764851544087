import React, { ReactNode } from "react";
import { QueryClientProviderProps } from "react-query";
import AxiosProvider from "./Axios";
import StyleSystem from "./StyleSystem";
import Query from "./Query";
import Intl from "./Intl";

type TProviders = {
  children: ReactNode;
  client: QueryClientProviderProps["client"];
};

export default function Providers({ children, client }: TProviders) {
  /**
   * Redux should be included in this component
   * There seems to be a race condition happening with
   * getBookieActivityLogs, this shouldn't be happening and implies
   * this is getting called too soon.
   * Part of a bigger refactor, but will need to be done.
   */
  return (
    <>
      {/* <Redux> */}
      <AxiosProvider>
        <Query client={client}>
          <Intl>
            <StyleSystem>{children}</StyleSystem>
          </Intl>
        </Query>
      </AxiosProvider>
      {/* </Redux> */}
    </>
  );
}
