import { chakra, Flex } from "@chakra-ui/react";

export const Container = chakra(Flex, {
  baseStyle: () => ({
    height: "100%",
    display: "flex",
    flexDirection: "row",
    color: "gray.500",
    overflow: "auto",
  }),
});

export const MainContent = chakra(Flex, {
  baseStyle: () => ({
    bg: "gray.400",
    padding: "90px 80px",
    width: "560px",
  }),
});

export const ChildContentWrapper = chakra(Flex, {
  baseStyle: () => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  }),
});

export const Logo = chakra(Flex, {
  baseStyle: () => ({
    marginBottom: "20px",
  }),
});
