import { extendTheme, withDefaultProps } from '@chakra-ui/react';
import base from './base';
// import { Button } from './components/button';
// import { FormError } from './components/formError';
// import { FormLabel } from './components/formLabel';
// import { Radio } from './components/radio';
// import { Popover } from './components/popover';
import { colors } from './foundations/colors';
import { fonts } from './foundations/fonts';
import { styles } from './styles';
// import { Modal } from './components/modal';
// import { Tooltip } from './components/tooltip';
// import { Alert } from './components/alert';
// import { Select } from './components/select';
// import { Input } from './components/input';

const slm = extendTheme(
  {
    ...base,
    theme: 'slm',
    colors,
    fonts,
    components: {
    //   FormLabel,
    //   FormError,
    //   Button,
    //   Popover,
    //   Modal,
    //   Tooltip,
    //   Alert,
    //   Radio,
    //   Select,
    //   Input,
    },
    styles,
    config: { cssVarPrefix: 'slm' },
  },
  withDefaultProps({
    defaultProps: { size: 'md' },
    components: [
    //   'Button',
    //   'Input',
    //   'NumberInput',
    //   'PinInput',
    //   'Select',
    //   'Textarea',
    ],
  })
);

export default slm;