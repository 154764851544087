import { chakra } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

export const StyledNavLink = chakra(NavLink, {
    baseStyle: () => ({
      h: '40px',
      display: 'flex',
      alignItems: 'center',
      my: '1.5',
      mx: '5',
  
      '&:hover': {
        bg: 'none',
      },
  
    }),
  });
