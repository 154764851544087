/* eslint-disable @typescript-eslint/no-explicit-any */
import { logEvent } from 'firebase/analytics';
import { analytics } from '../app/services/firebase/firebase.init';

export function logLoginEvent(user: any) {
  logEvent(analytics, 'sign_in', {
    uid: user.uid,
    email: user.email, // Avoid tracking sensitive user data unnecessarily
  });
}

export function logSignUpEvent(user: any) {
  logEvent(analytics, 'sign_up', {
    uid: user.uid,
    email: user.email, // Avoid tracking sensitive user data unnecessarily
  });
}

export function logErrorEvent(errorMessage: any, details: any) {
  logEvent(analytics, 'error', {
    errorMessage,
    details, // Avoid tracking sensitive user data unnecessarily
  });
}
