/**
 * Chakra's default line heights are bigger than
 * average, universally making them shorter & more inline
 * with design's styling.
 */
export const lineHeights = {
    normal: 'normal',
    none: 1,
    shorter: 1.15,
    short: 1.2,
    base: 1.25,
    tall: 1.5,
    taller: 1.625,
  };
  