import * as Yup from 'yup';

export const resetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
  });

  export const initialValues = {
    email: '',
  };