import { Coordinate } from '@/views/upload/Upload.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from '../../../../views/upload/Upload.types';

type TMapState = {
  location: Coordinate;
  zoom: number;
  lineToReview: FeatureCollection | undefined;
};

const initialState: TMapState = { 
  lineToReview: undefined,
  location: [-70.9, 42.35],
  zoom: 8,
};

const map = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setLineToReview: (
      state,
      action: PayloadAction<TMapState['lineToReview']>
    ) => ({
      ...state,
      lineToReview: action.payload,
    }),
    setLocation: (state, action: PayloadAction<Coordinate>) => ({
      ...state,
      location: action.payload,
    }),
    setZoom: (state, action: PayloadAction<number>) => ({
      ...state,
      zoom: action.payload,
    }),
    setError: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
  },
});

export const { setLineToReview, setLocation, setZoom, setLoading, setError } =
  map.actions;

export default map.reducer;
