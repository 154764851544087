import { chakra, Box, Flex } from '@chakra-ui/react';
import { Motion } from '../../common/components/Motion/Motion';


export const ContentContainer = chakra(Flex, {
  baseStyle: () => ({
    minHeight: '100vh',
    flexDir: 'column',
    background: 'gray.100',
    position: 'relative',
    
    '&::before': {
      content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0.8,
    },
  }),
});

export const ContentWrapper = chakra(Box, {
  baseStyle: () => ({
    w: '100%',
    height: '100%',
  }),
});

export const Content = chakra(Flex, {
  baseStyle: () => ({
    label: 'Content',
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: '1',
    // order: '2',
    height: '100%',
  }),
});

export const SidebarWrapper = chakra(Motion, {
  baseStyle: () => ({
    position: 'sticky',
    top: 0,
  }),
});

export const HeaderWrapper = chakra(Motion, {
  baseStyle: () => ({
    position: 'sticky',
    top: '0',
    w: '100%',
    zIndex: '1',
  }),
});
