import {
  WithCSSVar,
  chakra,
  Flex,
  ChakraComponent,
  FlexProps,
} from '@chakra-ui/react';
import { GroupBase, StylesConfig } from 'react-select';
import chroma from 'chroma-js';

interface TechniqueOption {
  value: string;
  label: string;
  color: string;
}

export const searchHomeBarStyles = (
  theme: WithCSSVar<Record<any, any>>,
  isMobile: boolean,
  isDesktop: boolean
): StylesConfig<TechniqueOption, boolean, GroupBase<TechniqueOption>> => ({
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    backgroundColor: state.isSelected ? '#2b2b2b' : '#262626',
    color: state.isSelected ? '#FFDA2A' : 'white',
    fontSize: isMobile ? '16px' : '16px',
    transition: 'background-color 0.2s ease, color 0.2s ease',
    '&:hover': {
      backgroundColor: '#2b2b2b',
      color: '#FFDA2A',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: isMobile ? '16px' : '16px',
    color: '#AAAAAA', // Light grey color for placeholder text
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: isMobile ? '16px' : '16px',
    color: 'white',
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: '20px', // Align the text more centrally
    paddingRight: '20px', // Ensure text is not cut off on the right
    paddingBottom: '10px',
    paddingTop: '10px',
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: '#262626',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
    borderRadius: theme.radii.md,
    marginTop: '8px',
  }),
  control: (provided, state) => ({
    ...provided,
    borderWidth: '2px',
    borderColor: state.isFocused ? '#FFDA2A' : '#2b2b2b',
    boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.15)',
    backgroundColor: '#292929',
    minHeight: isMobile ? '45px' : '55px',
    minWidth: isMobile ? '90vw' : '50vw',
    borderRadius: theme.radii.full,
    cursor: 'pointer',
    fontSize: isMobile ? '16px' : '16px',
    paddingRight: '10px',
    marginRight: '10px',
    marginLeft: isDesktop ? '0px' : '10px',
    position: 'relative',
    transition: 'border-color 0.2s ease, box-shadow 0.2s ease',
    '&:hover': {
      borderColor: '#FFDA2A',
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? '#FFDA2A' : '#AAAAAA',
    paddingRight: '10px',
    transition: 'color 0.2s ease',
    '&:hover': {
      color: '#FFDA2A',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none', // Remove the separator between the dropdown and input area
  }),
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
      borderRadius: theme.radii.sm,
      padding: '3px',
      transition: 'background-color 0.2s ease',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
    fontWeight: 'bold',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
  input: (styles) => ({
    ...styles,
    color: 'white',
  }),
});

// export const searchHomeBarStyles = (
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   theme: WithCSSVar<Record<any, any>>,
//   isMobile: boolean,
//   isDesktop: boolean,
// ): StylesConfig<TechniqueOption, boolean, GroupBase<TechniqueOption>> => ({
//   option: (provided, state) => ({
//     ...provided,
//     cursor: 'pointer',
//     backgroundColor: "black", //'rgb(23 23 23 / 1)',// theme.colors.brand[500],
//     // opacity: state.isSelected ? 0.3 : 0,
//     fontSize: isMobile ? "16px": '16px', // Tokenise
//     color: state.isSelected ? '#FFDA2A' : 'white' //'white' // theme.colors.brand[500],
//   }),
//   placeholder: (provided) => ({
//     ...provided,
//     fontSize: isMobile ? "16px":'16px', // Set the desired font size for the placeholder here
//   }),
//   singleValue: (provided) => ({
//     ...provided,
//     fontSize: isMobile ? "16px": '16px', // Set the font size for the value and placeholder here
//     position: 'absolute',
//     right: '10px', // Position the selected value outside the control container
//     top: '50%', // Adjust vertically as needed
//     transform: 'translateY(-50%)', // Center vertically relative to the control container
//   }),
//   valueContainer: (provided) => ({
//     ...provided,
//     paddingLeft: '30px', // theme.sizes[4],
//     // paddingRight: theme.sizes[4],
//     // height: '72px',
//     paddingBottom: '10px',
//     paddingTop: '10px',
//   }),
//   menuPortal: (provided) => ({
//     ...provided,
//     zIndex: '9999999999', // TOKEN
//     // position: isMobile ? 'sticky' : provided.position,
//     // top: isMobile ? '0px' : provided.top,
//     // background: "red"
//     // bottom: isMobile ? '100' : provided.bottom,
//   }),
//   menu: (styles) => ({
//     ...styles,
//     backgroundColor: 'rgb(23 23 23 / 1)',
//     // position: isMobile ? 'static' : styles.position,
//     // top: isMobile ? 'auto' : styles.top,
//     // bottom: isMobile ? '100' : styles.bottom,
//     // width: isMobile ? "90svw" : styles.width
//   }),
//   control: (provided, state) => ({
//     ...provided,
//     borderWidth: '0px',
//     '&:hover': state.isFocused
//       ? {
//           borderColor: '#C1CDC8',
//         }
//       : provided['&:hover'],

//     boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.15)',
//     backgroundColor: "#292929",
//     minHeight: isMobile ? '45px' : '55px',
//     minWidth: isMobile ? "90vw": '50vw',
//     borderRadius: theme.radii.full,
//     cursor: 'pointer',
//     fontSize: isMobile ? "16px": '16px',
//     paddingRight: '10px',
//     marginRight: "10px",
//     marginLeft: isDesktop ? "0px": "10px",
//     position: 'relative',
//   }),
//   dropdownIndicator: (provided, state) => ({
//     ...provided,
//     color: state.isFocused ? theme.colors.gray[600] : theme.colors.gray[500],
//     // boxSize: '40px',
//     // height: '80px',
//     paddingRight: '10px',
//   }),
//   indicatorSeparator: (provided) => ({
//     ...provided,
//     display: 'none',
//   }),
//   multiValue: (styles, { data }) => {
//     const color = chroma(data.color);
//     return {
//       ...styles,
//       backgroundColor: color.alpha(0.1).css(),
//     };
//   },
//   multiValueLabel: (styles, { data }) => ({
//     ...styles,
//     color:  data.color,
//   }),
//   multiValueRemove: (styles, { data }) => ({
//     ...styles,
//     color: data.color,
//     ':hover': {
//       backgroundColor: data.color,
//       color: 'white',
//     },
//   }),

//   input: (styles) => ({
//     ...styles,
//     color: 'white', // Set the input text color to white
//   }),
// });

export const FlexSectionRow: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: () => ({
    label: 'FlexSectionRow',
    w: '100%',
    px: '5',
    flexDir: 'column',
  }),
});

export const FlexPageWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: () => ({
    label: 'FlexPageWrapper',
    minH: '100vh',
    bg: '#171717',
    flexDir: 'column',
    // '& > div:nth-child(even)': {
    //   backgroundColor: '#171717',
    // },
  }),
});
