import { ChakraComponent, Flex, FlexProps, chakra } from "@chakra-ui/react";

export const FlexPageWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
    baseStyle: () => ({
      label: 'FlexPageWrapper',
      minH: '100vh',
      bg: '#171717',
      px: '20px',
      w: '100vw',
      flexDir: 'column',
      //justifyContent: 'center',
      alignItems: 'center',
      '& > div:nth-child(even)': {
        backgroundColor: '#171717',
      },
    }),
  });

  export const FlexWrapperForm: ChakraComponent<'div', FlexProps> = chakra(Flex, {
    baseStyle: () => ({
      label: 'FlexWrapperForm',
      width: '90vw',
      flexDir: 'column',
      gap: '20px'
    }),
  });