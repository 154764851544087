import React, { FC } from 'react';
import {
  Grid,
  Box,
  Divider,
  Text,
  Link,
  HStack,
  Image,
  Icon,
  Flex,
} from '@chakra-ui/react';
import { SocialFacebookCircular } from '@styled-icons/typicons/SocialFacebookCircular';
import { SocialTwitterCircular } from '@styled-icons/typicons/SocialTwitterCircular';
import { Instagram } from '@styled-icons/boxicons-logos/Instagram';
import { SocialLinkedinCircular } from '@styled-icons/typicons/SocialLinkedinCircular';
import { SLMLogoWhite, X } from '../../../assets/logos';

const Footer: FC = () => {
  return (
    <Grid
      bg="#171717"
      h="200px"
      bottom={-200}
      position="absolute"
      width="100%"
      templateRows="repeat(4, auto)"
      templateColumns="repeat(2, 1fr)"
      padding="1rem"
      color="white"
      gap={0}
      borderTopWidth="0.5px"
      borderColor="white"
    >
      <Flex
        gridColumn="1 / 2"
        justifySelf="start"
        mr="5"
        w="100%"
        alignItems="center"
        mb="12px"
      >
        <Image src={SLMLogoWhite} h="40px" />
      </Flex>
      <Box gridColumn="2 / 3" justifySelf="end" mb="2">
        <Text fontWeight="bold" mb="3">
          Connect with us
        </Text>
        <HStack spacing={3} justifyContent="center">
          {/* <Link href="https://www.facebook.com/tapdex" isExternal>
            <Icon as={SocialFacebookCircular} boxSize="8" />
          </Link> */}
          <Link href="https://twitter.com/tapdex_io" isExternal>
            {/* <Icon as={SocialTwitterCircular} boxSize="8" /> */}
            <Image src={X} boxSize="7" />
          </Link>
          <Link href="https://www.instagram.com/tapdex.io/" isExternal>
            <Icon as={Instagram} boxSize="8" />
          </Link>
          {/* <Link href="https://www.linkedin.com/company/tapdex" isExternal>
            <Icon as={SocialLinkedinCircular} boxSize="8" />
          </Link> */}
        </HStack>
      </Box>
      <Divider gridColumn="1 / 3" borderColor="white" />
      <Flex gridColumn="1 / 3" justifyContent="space-between" w="100%">
        <Text fontSize="sm" mt="4" mb="2" mr="3">
          2024 AetherCode Pty Ltd, trading as TapDex, All Rights Reserved.
          {/* <br />
          STRAIGHT LINE MISSION® and the Straight Line Mission Design are
          registered trademarks of AetherCode Pty Ltd in Australia as well as
          certain other jurisdictions. */}
        </Text>

        <HStack spacing={5} mt="1">
          <Link href="/privacy" color="white" fontSize="sm">
            Policy
          </Link>
          <Link href="/terms" color="white" fontSize="sm">
            Terms
          </Link>
          {/* <Link href="/cookie-policy" color="white" isExternal>
            Cookie Policy
          </Link>
          <Link href="/manage-cookies" color="white" isExternal>
            Manage Cookies
          </Link> */}
        </HStack>
      </Flex>
    </Grid>
  );
};

export default Footer;
