import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { initialValues, signUpSchema } from '../services/SignUp.config';
import { TFormikHOC, TSignUpForm } from '../SignUp.types';
import { useSignUpFormHOC } from '../services/SignUp.hooks';

export default function FormikHOC({ children }: TFormikHOC) {
  const { onSubmit } = useSignUpFormHOC();

  return (
    <Formik<TSignUpForm>
      initialValues={initialValues}
      validationSchema={signUpSchema}
      onSubmit={async (
        values: TSignUpForm,
        { setSubmitting, resetForm }: FormikHelpers<TSignUpForm>
      ) => {
        setSubmitting(true);
        onSubmit(values);
        setSubmitting(false);
        resetForm({ values: initialValues });
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
}
