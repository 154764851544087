import { useAppDispatch } from '@/hooks/useRedux';
import { TSignInForm } from '../SignIn.types';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { setAuthError } from '@/redux/Global.slice';
import { logErrorEvent, logLoginEvent } from '@/utils/analytics';

export const useSignInFormHOC = () => {
  const dispatch = useAppDispatch();
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const navigate = useNavigate();

  const onSubmit = async (form: TSignInForm) => {
    if (!form) return;

    signInWithEmailAndPassword(auth, form.email, form.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;

        // Track login event
        logLoginEvent(user);
        navigate('/');
        dispatch(setAuthError(''));
      })
      .catch((error) => {
        const errorMessage = error.message;
        logErrorEvent(error, 'Error sing in email pw');
        dispatch(setAuthError(errorMessage));
      });
  };

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        if (!result) {
          logErrorEvent(result, 'Error no result after signin with google');
          return;
        }

        // The signed-in user info.
        const user = result.user;

        // Track login event
        logLoginEvent(user);
        navigate('/');
        dispatch(setAuthError(''));
      })
      .catch((error) => {
        const errorMessage = error.message;

        logErrorEvent(error, 'Error signin with google');
        dispatch(setAuthError(errorMessage));
      });
  };

  return {
    onSubmit,
    signInWithGoogle,
  };
};
