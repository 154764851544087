import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import * as geofirestore from 'geofirestore';
import { firebaseConfig } from './firebase.config';
import { getAnalytics } from 'firebase/analytics';

// Initialize Firestore and GeoFirestore
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = firebase.firestore();
const GeoFirestore = geofirestore.initializeApp(db);

export { db, GeoFirestore, analytics };
