import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Card } from '../../common/components/Card/Card';
import { ErrorLayout } from '../../layouts/ErrorLayout/ErrorLayout';
import { FormattedMessage } from 'react-intl';
import { Button } from '@chakra-ui/react';

const ErrorPage: React.FC<FallbackProps> = ({ resetErrorBoundary }) => {
  const clickHandler = () => {
    resetErrorBoundary();
  };

  return (
    <ErrorLayout>
      <Card title={<FormattedMessage id="generic.error.title" />}>
        <FormattedMessage id="generic.error.header" />

        <Button type="button" onClick={clickHandler}>
          <FormattedMessage id="generic.error.button" />
        </Button>
      </Card>
    </ErrorLayout>
  );
};

export default ErrorPage;
