import * as Yup from 'yup';

export const signUpSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
    fullName: Yup.string()
      .max(50, 'Full name cannot exceed 50 characters')
      .required('Full name is required'),
  });

  export const initialValues = {
    fullName: '',
    email: '',
    password: '',
  };