import React from 'react';
import { FlexWrapperForm } from '../SignUp.styles';
import Input from '@/common/components/Input/Input';
import {
  Text,
  Button,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { TSignUpForm } from '../SignUp.types';
import { useAppSelector } from '@/hooks/useRedux';

const Form: React.FC = () => {
    const context = useFormikContext<TSignUpForm>();
    const { isAuthLoading } = useAppSelector((state) => state.globalSlice);
  return (
   
        <FlexWrapperForm maxW="300px">
          <Input
            name="fullName"
            label="Full Name"
            labelProps={{ color: 'white' }}
            color="white"
            
          />
          <Input name="email" label="Email" labelProps={{ color: 'white' }} color="white"/>
          <Input
            name="password"
            label="Password"
            type="password"
            labelProps={{ color: 'white' }}
            color="white"
          />
          <Text mt="20px" color="#A6A6A6" fontSize="14px" alignSelf="center" mb="-5px">No credit card required</Text>
        <Button
        aria-label="google"
        w="100%"
        h="45px"
        borderRadius="full"
        mb="20px"
        bg="#FFDA2A"
        isLoading={isAuthLoading}
        onClick={() => context.submitForm()}
      >
        <Text>Free Sign Up</Text>
      </Button>
        </FlexWrapperForm>
    

    
  );
};

export default Form;
