import React, { FC } from 'react';
import { FixedSizeList as List } from 'react-window';
import { MenuListProps, GroupBase } from 'react-select';

const ITEM_HEIGHT = 35;

const MenuList: FC<MenuListProps<any, false, GroupBase<any>>> = ({
  options,
  children,
  maxHeight,
  getValue,
}) => {
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * ITEM_HEIGHT;

  return (
    <List
      height={maxHeight}
      itemCount={children ? React.Children.count(children) : 0} // Ensure children is not null/undefined and get the correct count
      itemSize={ITEM_HEIGHT}
      initialScrollOffset={initialOffset}
      width="100%"
    >
      {({ index, style }: { index: number; style: React.CSSProperties }) => (
        <div style={style}>
          {React.Children.toArray(children)[index]}{' '}
          {/* Safely access children */}
        </div>
      )}
    </List>
  );
};

export default MenuList;
