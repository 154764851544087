import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { initialValues, resetPasswordSchema } from '../services/ResetPassword.config';
import { TFormikHOC, TResetPasswordForm } from '../ResetPassword.types';
import { useResetPasswordFormHOC } from '../services/ResetPassword.hooks';


export default function FormikHOC({ children }: TFormikHOC) {
  const { onSubmit } = useResetPasswordFormHOC();

  return (
    <Formik<TResetPasswordForm>
      initialValues={initialValues}
      validationSchema={resetPasswordSchema}
      onSubmit={async (
        values: TResetPasswordForm,
        { setSubmitting, resetForm }: FormikHelpers<TResetPasswordForm>
      ) => {       
        setSubmitting(true);
        onSubmit(values);
        setSubmitting(false);
        resetForm({ values: initialValues });
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
}
