import { useAppDispatch } from '@/hooks/useRedux';
import { TResetPasswordForm } from '../ResetPassword.types';
import {
  getAuth,
  sendPasswordResetEmail
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { setAuthError } from '@/redux/Global.slice';

export const useResetPasswordFormHOC = () => {
  const dispatch = useAppDispatch();
  const auth = getAuth();
  const navigate = useNavigate();

  const onSubmit = async (form: TResetPasswordForm) => {
    console.log("🚀 ~ onSubmit ~ form:", form)
    
    if (!form) return;

    sendPasswordResetEmail(auth, form.email)
    .then(() => {
      // Password reset email sent!
      // ..
      console.log('Password reset email send')
      navigate('/signin')
    })
    .catch((error) => {
      // const errorCode = error.code;
      const errorMessage = error.message;
      dispatch(setAuthError(errorMessage));
      // ..
    });
  };


  return {
    onSubmit,
  };
};
