import React, { createContext, useContext, ReactNode } from 'react';
import { db, GeoFirestore } from './firebase.init';

type FirestoreContextType = {
  firestore: typeof db,
  geofirestore: typeof GeoFirestore,
} | null;

const FirestoreContext = createContext<FirestoreContextType>(null);

interface FirestoreProviderProps {
  children: ReactNode;
}

export const useFirestore = () => {
  const context = useContext(FirestoreContext);

  if (!context) {
    throw new Error("useFirestore must be used within a FirestoreProvider");
  }
  
  return context;
};

export const FirestoreProvider: React.FC<FirestoreProviderProps> = ({ children }) => {
  const firestoreValues = { firestore: db, geofirestore: GeoFirestore };
  return (
    <FirestoreContext.Provider value={firestoreValues}>
      {children}
    </FirestoreContext.Provider>
  );
};