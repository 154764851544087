/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import Select, {
  GroupBase,
  StylesConfig,
  DropdownIndicatorProps,
  createFilter,
} from 'react-select';
import { useSearchBarTechniques } from './services/SearchBarTechniques.hooks';
import { searchBarStyles } from './SearchBarTechniques.styles';
import {
  useTheme,
  Text,
  WrapItem,
  Wrap,
  Flex,
  IconButton,
  Icon,
  useMediaQuery,
} from '@chakra-ui/react';
import { DropdownIndicator } from './components/SearchButton';
import { FormattedMessage } from 'react-intl';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { TTechniqueOption } from '@/api/dbModels.types';
import MenuList from './components/MenuList';

interface TechniqueOption {
  value: string;
  label: string;
  color: string;
}

type SearchBarGeoCoderProps = {
  styles?: StylesConfig<TechniqueOption, boolean, GroupBase<TechniqueOption>>;
  placeholder?: React.JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: React.FC<DropdownIndicatorProps<any, false>>;
  selectedOptions: TTechniqueOption[];
  aggregates: TTechniqueOption[];
  onSelect: (r: any) => any;
};

export const SearchBarGeoCoder: FC<SearchBarGeoCoderProps> = ({
  styles,
  placeholder,
  icon,
  aggregates,
  selectedOptions,
  onSelect,
}) => {
  const theme = useTheme();

  const [isDesktop] = useMediaQuery('(min-width: 1000px)');

  return (
    <Flex flexDir="column" mb={!selectedOptions.length ? '0px' : '15px'}>
      <Select
        filterOption={createFilter({ ignoreAccents: false })}
        // menuPortalTarget={document.body}
        // menuIsOpen={menuIsOpen}
        // onMenuClose={() => setMenuIsOpen(true)} // Prevent the menu from closing
        name="SearchBarGeoCoder"
        noOptionsMessage={() => null}
        // isSearchable={true}
        // options={techniqueOptions}
        options={aggregates}
        menuPortalTarget={document.body}
        // menuPosition={'fixed'}
        // isMulti
        controlShouldRenderValue={false}
        styles={styles ?? searchBarStyles(theme)}
        components={{ DropdownIndicator: icon ?? DropdownIndicator, MenuList }}
        theme={(theme) => ({
          ...theme,
          // borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#292929',
            primary: '#292929',
          },
        })}
        placeholder={
          placeholder ?? (
            <Text fontSize="2sm">
              <FormattedMessage id="searchbar.placeholder" />
            </Text>
          )
        }
        value={selectedOptions}
        onChange={(v: TTechniqueOption) => {
          onSelect([v]);
        }}
      />
      {!!selectedOptions.length && (
        <Wrap mt="40px" mx={isDesktop ? '3px' : '14px'}>
          {selectedOptions.map(({ value, label }, i) => (
            <WrapItem
              key={value}
              // bg="rgba(255, 218, 42, 0.3)"
              bg="transparent" // rgba(255, 218, 42, 0.12)"
              border="1px solid rgb(178 178 178)" // "1px solid rgba(255, 218, 42, 0.4)"
              // borderWidth="1px"
              // borderColor="#FFDA2A"
              py="5px"
              borderRadius="5px"
              pl="8px"
              alignItems="center"
            >
              <Text
                color="rgb(178 178 178)"
                fontWeight="500"
                fontSize="14px"
                letterSpacing=".2px"
                fontFamily="Roboto"
                pb=".5px"
                mr="1px"
              >
                {label}
              </Text>
              <IconButton
                aria-label="Search"
                h="5"
                w="2"
                minW="7"
                mr="1px"
                bg="transparent"
                justifyContent="center"
                _hover={{ bg: 'transparent' }}
                onClick={() => {
                  const updatedSelectedTechniqueLabels = selectedOptions.filter(
                    (cstl) => cstl.value !== value
                  );
                  onSelect(updatedSelectedTechniqueLabels);
                }}
              >
                <Icon
                  as={CloseOutline}
                  color="rgb(178 178 178)"
                  boxSize="22px"
                />
              </IconButton>
            </WrapItem>
          ))}
        </Wrap>
      )}
    </Flex>
  );
};
